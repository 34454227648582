import { Box, Button, IconButton, Stack, Link, Menu, MenuItem} from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MyGrid = function (props) {
  return <Box sx={{
    display: 'grid',
    width: '100%',
    gap: `${ props.spacing * 8 + "px" } 4px`,
    gridTemplateColumns:  '200px repeat(7, 1fr)',
    gridAutoRows: 'minmax(4px, auto)',
    ...props.sx
  }}>{props.children}</Box>
}
const RoundButton = function (props) {
  return <IconButton sx={{
    marginRight: '1rem',
    marginLeft: '1rem',
    borderRadius: '1.25rem',
    border: 0,
    backgroundColor: 'white',
    color: 'gray',
    textAlign: 'center',
    fontSize: '1rem',
    '&:hover': {
      color: 'white',
      backgroundColor: 'gray'
    },
    ...props.sx
  }} onClick={props.onClick}>{props.children}</IconButton>
}
//********************************************************************************************************
function ColHeader(props) {
  return <Box sx={{px:1, textAlign: 'center'}} className="w3-indigo">{props.children}</Box>;
}
//********************************************************************************************************
function RowHeader(props) {
  return <Box sx={{px:1}} className="w3-indigo">{props.children}</Box>;
}
//********************************************************************************************************
function CellHeader(props) {
  return <Box sx={{ px: 1, textAlign: 'right', marginBottom:"3px" }}
    className={props.children ? "w3-blue":"w3-blue"}>
      { props.children }
    </Box >;
}
//********************************************************************************************************
function dayOfYear(date) {									// Jan 1 is day 1 not zero
  if (typeof date != "object") date = new Date(date+"T00:00:00");
  var onejan = new Date(date.getFullYear(),0,1);
  return Math.ceil((date - onejan) / 86400000)+1;
}
//********************************************************************************************************
function Line(props) {
  
  return <Box sx={{ px: 1, textAlign: 'center', borderTop: "1px dotted navy" }}
  className={props.hilite ? "w3-ios-yellow" : props.isHoliday ? "w3-2021-green-ash" : "w3-light-gray"}>
    {props.elem}
  </Box>;
}
//********************************************************************************************************
function Spacing(props) {
  return <Box sx={{px:1,height:"2px"}} className="w3-white">&nbsp;</Box>;
}
//********************************************************************************************************

let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let weekDays = ["", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
let initialMonth = new Date().getMonth();
//********************************************************************************************************
function MonthGrid(props) {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [hiliteAnchor, setHiliteAnchor] = useState(null);
  let location = useLocation();
  try { props = { ...props, ...location.state } } catch (err) { }
  let [state, setState] = useState({ month: initialMonth, grid: "monthly", nameType: "lastName", edit: false, hilite:props.hilite ?? 0 });

  //======================================================
  function isHoliday(doy) {
    if(props.holidays.includes(doy)) return true;
    return false;
  }
  //======================================================
  function calcMonth(year=props.year, weekStarts = 0) {
    let now = new Date();
    let month = state.month + 1;
    if (typeof month === "undefined") month = now.getMonth() + 1;
    if (typeof year === "undefined") year = now.getFullYear();

    var firstDate = new Date(year, month - 1, 1);
    var firstDOW = firstDate.getDay();
    var nBlanks = firstDOW - weekStarts; if (firstDOW < weekStarts) nBlanks += 7;
    var lastDay = daysInMonth(month, year);
    var nRows = Math.ceil((lastDay + nBlanks) / 7);

    let headers = [0, 1, 2, 3, 4, 5, 6, 7].map((n) => <ColHeader key={"wk" + n}>{props.condensed ? weekDays[n].substring(0, 3) : weekDays[n]}</ColHeader>);
    let spacing = [0, 1, 2, 3, 4, 5, 6, 7].map((n) => <Spacing key={"ks" + n} />);

    let dayNo = 1;
    let blanksDone = 0;
    let allRows = [headers];
    
    for (let rr = 0; rr < nRows; rr++) {
      let cellHeader = [<CellHeader key={"kc" + allRows.length + rr} />];
      let lines = new Array(props.rowHeaders.length);
      for (let aa = 0; aa < lines.length; aa++) {
        lines[aa] = new Array(8);
        lines[aa][0] = <RowHeader key={"kl" + allRows.length + rr + aa}>{props.rowHeaders[aa]}</RowHeader>;
      }
      for (let cc = 1; cc < 8; cc++) {
        if (blanksDone < nBlanks || dayNo > lastDay) {
          cellHeader.push(<CellHeader key={"k" + allRows.length + rr + cc}>&nbsp;</CellHeader>);
          for (let aa = 0; aa < lines.length; aa++) lines[aa][cc] = <Line elem="" key={"kk" + allRows.length + rr + cc + aa} />;
          blanksDone++;
        } else {
          cellHeader.push(<CellHeader key={"k" + allRows.length + dayNo}>{dayNo}</CellHeader>);
          let doy = dayOfYear(new Date(year, month - 1, dayNo));
          for (let aa = 0; aa < lines.length; aa++) try {
            let elem = props.content[props.rowHeaders[aa] + "-" + doy];
            let hilite = false;
            try { if (elem.props.children == props.content.names[state.hilite]) hilite = true; } catch (err) { }
            try { if (elem == props.content.names[state.hilite]) hilite = true; } catch (err) { }
            lines[aa][cc] = <Line elem={elem} isHoliday={isHoliday(doy)} key={"k" + doy + aa} hilite={hilite} />;
          } catch (err) { lines[aa][cc] = <Line elem="" key={"k" + allRows.length + rr + cc + dayNo} /> }
          dayNo++;
        }
      }
      allRows.push(spacing, cellHeader, ...lines);
    }
    return allRows;

    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }
  }
  //======================================================
  function setMonth(m) {
    if (m > 11) m = 0;
    if (m < 0) m = 11;
    initialMonth = m;
    setState({ ...state, month: m });
    setMenuAnchor(null);
  }
  //======================================================
  function setHilite(n) {
    try {
      if (n > props.content.names.length || n < 0) n = 0;
      setState({ ...state, hilite: n });
      location.state = {...location.state,hilite:n, hilitedPerson: props.content.names[n] }
    } catch(err) {}
    setHiliteAnchor(null);
  }
  //======================================================

  let arNames = Array.isArray(props?.content?.names) ? props?.content?.names : [];
  return (<>
    <Stack direction="row" justifyContent="flex-end" sx={{mt:1}}>
      <Box sx={{ mt: 1, mr: 3 }}>Highlight for:</Box>
      <Box sx={{ mt: 1, pl:1, mb:1, width: "150px", cursor: "pointer", mr: 2 }} className="w3-ios-yellow"
        onClick={(ev) => setHiliteAnchor(ev.currentTarget)}>{props?.content?.names[state.hilite]}
      </Box>
      <Menu anchorEl={hiliteAnchor} open={Boolean(hiliteAnchor)} onClose={() => setHiliteAnchor(null)}>
        {arNames.map((p, index) =>
          <MenuItem divider={true} onClick={() => setHilite(index)} key={"key"+index}>{p}</MenuItem>
        )}
      </Menu>
      <RoundButton onClick={() => setMonth(state.month - 1)}><i className="fa fa-chevron-left" /></RoundButton>
      <Button sx={{ textAlign: 'center', width: "80px" }} onClick={(ev) => setMenuAnchor(ev.currentTarget)}>{monthNames[state.month]}</Button>
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        {monthNames.map((m, index) =>
          <MenuItem divider={true} onClick={() => setMonth(index)} key={m}>{m}</MenuItem>
        )}
      </Menu>
      <RoundButton onClick={() => setMonth(state.month + 1)}><i className="fa fa-chevron-right" /></RoundButton>
    </Stack>
    <MyGrid spacing={0} sx={{ p: 2 }}>
      {calcMonth().map(row => {
        return row.map((component, colNo) => component);
      })}
    </MyGrid>
    <Box sx={{ borderBottom: "2px solid silver", mx: 2 }}></Box>
  </>)
}

export default MonthGrid;