import {
  Box, Typography, Tooltip, Stack, Button, FormControlLabel, Checkbox, 
} from '@mui/material';
import DatePicker, {Calendar, DateObject} from "react-multi-date-picker";
import { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../App';
import { DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';

//********************************************************************************************************
const headColor = "#ee1111";
let x = window.innerWidth - 1200;
const columns = [
  { field: 'id', headerName: 'Ref No', width: 110,
    renderCell: (params) => 
      params.row.id[0] === "G" ? params.row.timeRead === "" ? <Button>Mark Read</Button>: ""  : params.row.id
  },
  { field: 'sender', headerName: 'Sender', width: 150},
  { field: 'dept', headerName: 'Department', width: 150},
  { field: 'name', headerName: 'Name', width: 150},
  { field: 'pagerNo', headerName: 'Device', width: 150},
  { field: 'message', headerName: 'Message', width: x,
    renderCell: (params) => <Box title={params.row.message} position="bottom">{ params.row.message}</Box>
  },
  { field: 'timeSent', headerName: 'Time Sent', width: 150},
  { field: 'timeRead', headerName: 'Time Read', width: 150},
  { field: 'indicator', headerName:"", width: 50, 
    renderCell: (params) => 
      params.row.timeRead ? <i className="fa fa-circle w3-text-green" /> : <i className="fa fa-circle w3-text-red" />
  },
];
//********************************************************************************************************
function Messages(props) {
  const [ctx, setContext] = useContext(GlobalContext);
  let [state, setState] = useState({ from: new DateObject().subtract(1, "month"), to: new DateObject(), hideViewed: false });
  let [rows, setRows] = useState({});


  //======================================================
  function handleChange(event) {
    try {
      event.preventDefault();
      if (event.target.type === "checkbox") {
        state[event.target.name] = !!!state[event.target.name];
      } else state[event.target.name] = event.target.value;
      setState({ ...state });
    } catch (err) { }
  }
  //======================================================
  function handleDate(name, dateObj) {
    state[name] = dateObj;
    setState({ ...state });
    getData();
  }
  //======================================================
  async function updateReadStamps(rows) {
    rows = await ctx.U.getViewTime(rows);
    setRows({ ...rows});
  }
  //======================================================
  async function gridCellClick(obj) {
    if (obj.field == "id") try {
      rows[obj.id].timeRead = ctx.U.logTimeRead(obj.id, new DateObject());
    setRows({ ...rows });
    } catch(err) {}
    if (obj.field == "message") try {
      ctx.T.alert(obj.row.message,"Message")
    } catch(err) {}
  }
  //======================================================
  async function getData() {
    let root = ctx.T.loading("Loading ...");
    try {
      let from = dayStartStamp(state.from);
      let to = dayEndStamp(state.to);
      let [data, _] = await ctx.T.post("find", { collection: "Message", query: { sender: ctx.T.user.email, firstStamp: { $gte: from, $lte: to } } });
      let rows = {};
      data.map(row => { rows[row._id] = row });
      setRows(rows);
      updateReadStamps(rows);
    } catch (err) { }
    root.unmount();

    function dayStartStamp(dateObj) {
      dateObj.hour = 0;
      dateObj.minute = 0;
      dateObj.second = 1;
      return dateObj.toUnix() * 1000; 
    }
    function dayEndStamp(dateObj) {
      dateObj.hour = 23;
      dateObj.minute = 59;
      dateObj.second = 59;
      return dateObj.toUnix() * 1000;
    }
  }
  //======================================================
  useEffect(() => {
    getData();
  },[])
  //======================================================
  return (<>
    <Box className={ctx.bkSidebar2} sx={{px:2}}>
      <Stack direction="row" alignItems="center" sx={{ ml: 2, mt:"2px" }}>
        <Box sx={{mr:2,whiteSpace: "nowrap"}}>From:</Box>
        <DatePicker name="from" value={state.from} format="MM/DD/YYYY" onChange={(x)=>handleDate("from",x)} />
        <Box sx={{mx:2,whiteSpace: "nowrap"}}>To:</Box>
        <DatePicker name="to" value={state.to} format="MM/DD/YYYY" onChange={(x)=>handleDate("to",x)} />
        <FormControlLabel control={<Checkbox name="hideViewed" className={ctx.bkSidebar2} value={true} onClick={handleChange} checked={state.hideViewed} />} label="Hide viewed" sx={{ ml: 2 }} />
        <Box sx={{display:"flex", flexGrow:1}}></Box>
        <Typography variant="h6" onClick={getData} sx={{cursor:"pointer"}}><i className="fa fa-refresh" >&nbsp;&nbsp;</i>REFRESH</Typography>
      </Stack>
    </Box>


    <Box sx={{ mx: 5, mt: 2, width: 'auto', height: window.innerHeight-150 }}>
      <DataGrid
        rows={Object.entries(rows).map(([key, row]) => ({...row,id:key}) ).filter((row)=>!state.hideViewed ||ctx.T.isBlank(row.timeRead))}
        columns={columns}
        onCellClick={gridCellClick}
        initialState={{
          sorting: {
            sortModel: [{ field: "timeSent", sort: "desc" }],
          },
        }}
        autoPageSize
      />
    </Box>
</>)
}
//********************************************************************************************************
export default Messages;
