import { Box, Button, Checkbox, Grid, FormGroup, FormControlLabel } from '@mui/material';
import { useState, useContext } from 'react';
import { GlobalContext } from '../App';


let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function WeekList(props) {
  const [weeks, setWeeks] = useState(props.weeks);
  // let [ctx, setContext] = useContext(GlobalContext);

  const today = new Date();
  const year = today.getFullYear();
  let day1 = new Date(year, 0);
  while (day1.getDay() !== 1) day1 = new Date(day1.getTime() + 86400000);
  
  //======================================================
  function handleChange(event) {
    event.preventDefault();
    try {
      let weekNo = Number(event.target.name.replace(/\D/g,""));
      console.log(event.target.name,weekNo,event.target.value)
      weeks[weekNo] = !!! weeks[weekNo];
      setWeeks([...weeks]);
    } catch (err) { }
  }
  //======================================================
  function all(props) {
    setWeeks([...Array(52).fill(1)]);
  }
  //======================================================
  function none(props) {
    setWeeks([...Array(52).fill(0)]);
  }
  //======================================================
  function WeekSet(props) {
    let dt1 = new Date(day1.getTime() + 7 * props.weekNo * 86400000);
    let dt2 = new Date(dt1.getTime() + 6 * 86400000);
    let month1 = monthName[dt1.getMonth()] + " ";
    let month2 = monthName[dt2.getMonth()] + " ";
    if (month1 === month2) month2 = "";
    return (<FormGroup>
      <FormControlLabel control={<Checkbox checked={weeks[props.weekNo]} onChange={handleChange} name={`week${ props.weekNo }`} value={true}/>}
        label={`Week ${ props.weekNo+1 } (${month1}${dt1.getDate()}-${month2}${dt2.getDate()})`} />
    </FormGroup>);
  }
  //======================================================

  return (<>
    <Grid container spacing={2} m={3} columns={{xs:6, md:9, lg:12}}>
      <Grid container direction="row">
        <Box component="p">Fill these weeks</Box>
        <Button onClick={all}>All</Button>
        <Button onClick={none}>None</Button>
      </Grid>
      {[...Array(52)].map((_, nn) => <Grid item xs={3} md={3} lg={3} key={nn}><WeekSet weekNo={nn}/> </Grid>)}
    </Grid>
  </>)

}

export default WeekList;