import { useContext, useState, useEffect } from 'react';
import { Box, Stack, Button, TextField } from '@mui/material';
import { useParams } from "react-router-dom";
import { GlobalContext } from '../App';
import PublicView from "./PublicView";


//********************************************************************************************************
function View(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  const params = useParams();
  let [state, setState] = useState(Object.assign({slug:"",accountNo:"",securityCode:""},params));

  //======================================================
  function handleChange(event) {
    event.preventDefault();
    state[event.target.name] = event.target.value;
    setState({...state});
  }
  //======================================================
  async function go() {
    if (ctx.T.isBlank(params.slug) || ctx.T.isBlank(params.accountNo)) return;
    let [obj, _] = await ctx.T.post("getPublished", state);
    ctx.schedule = obj;
    setContext({...ctx});
    // console.log(ctx.T.dateTime(obj.stamp),obj.views);
  }
  //======================================================
  useEffect(() => {
    go();
  }, []);
  //======================================================

  return (<>
    {!ctx.T.isBlank(ctx?.schedule?.info) ? <Box sx={{ mt: 2 } }><PublicView /> </Box>
    :
    <>
      <Box sx={{ display: "flex", flexDirection: "row", px: 2 }}>
        <Box component="h2" sx={{ flexGrow: 1 }}>Find Published Schedule</Box>
      </Box>
        <Stack direction={{ xs: "column", md: "row" }} justifyContent="center">
        <Box className='w3-pale-green' sx={{ px: 5, pt: 3, borderRadius: "1rem" }}>
          <table style={{ width: "100%", padding: "8px" }}>
            <tbody><tr>
              <td style={{paddingTop:12}}><TextField size="small" name="accountNo" label="Account Number" value={state.accountNo} onChange={handleChange} sx={{ backgroundColor: "white" }}></TextField></td>
            </tr><tr>
                <td style={{paddingTop:12}}><TextField size="small" name="slug" label="Schedule Name" value={state.slug} onChange={handleChange} sx={{ backgroundColor: "white" }}></TextField></td>
              </tr><tr>
                <td style={{paddingTop:12}}><TextField size="small" name="securityCode" label="Security Code (Optional)" value={state.securityCode} onChange={handleChange} sx={{ backgroundColor: "white" }}></TextField></td>
              </tr><tr>
                <td align="right"><Button color="success" variant="contained" sx={{ my: 3 }} onClick={go}>Go</Button></td>
              </tr></tbody>
          </table>
        </Box>
      </Stack>
    </>}
  </>)
}

export default View;