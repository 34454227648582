import { useContext, useState } from 'react';
import {
  Box, Grid, Stack, Button, FormGroup, FormControlLabel, Checkbox, FormControl,FormLabel,RadioGroup, Radio,
  Container, List, ListItem, ListItemText, ListItemButton, Divider, TextField
} from '@mui/material';
// import { PayPalButton } from "react-paypal-button-v2";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../App';


//********************************************************************************************************
function User(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  let navigate = useNavigate();
  let [_, __] = useState(calcExpiry());
  let [user, setUser] = useState(ctx.T.user);
  let [showPayPal, setShowPayPal] = useState(false);

  let paypalOptions = {
    clientId: "Afl8-2Mfjzs-H9w67HJZJEN0VhT975CxDb7sQp86g67MVPZPpGwuJNgYRa_A7Q8LpEdVnFfxUzzSpIF2",
    currency: "USD",
    vault: true,
    disableFunding: "credit",
    intent: "capture",
  }
  //======================================================
  function handleChange(event) {
    event.preventDefault();
    try {
      // console.log(event.target);
      let obj = {...user};
      if (event.target.type === "checkbox") {
        obj[event.target.name] = !!! obj[event.target.name];
      } else obj[event.target.name] = event.target.value;
      ctx.T.user = obj;
      setUser(obj);
    } catch (err) { }
  }
  //======================================================
  function createUpgrade(data, actions) {
    return actions.order.create({
      purchase_units: [{
        custom_id: ctx.T.user._id,
        amount: { value: '40.00' }
      }]
    });
  }
  //======================================================
  function upgradeApproved(data, actions) {
    return actions.order.capture().then(async function (details) {
      setShowPayPal(false);
      ctx.T.user.paid = true;
      ctx.T.alert("<p>Thank You!", 'Payment received from ' + details.payer.name.given_name + '</p><p>Your account has been upgraded to annual membership.  Best of luck with your exam!');
      await ctx.T.post("upgradeToAnnual", { user: ctx.T.user, web: details });
      navigate("/testslist");
    });
  }
  //======================================================
  async function cancelSubscription() {
    let cancel = await ctx.T.confirm("Do you want to cancel this subscription");
    if (!cancel) return;

    let [_, result] = await ctx.T.post("cancelSubscription", { subscriptionID: ctx.T.user.subscriptionID, email: ctx.T.user.email });
    if (result == "success") ctx.T.alert("Your recurring subscription has been cancelled", "Subscription Cancelled");
    else ctx.T.alert(result, "Cancelation Failed");
  }
  //======================================================
  async function saveUser() {
    Object.assign(ctx.T.user, user);
    delete user.user;   // fixes old error

    let [result, _] = await ctx.T.post("saveUser");
    if (result.ok) {
      try {
        Object.assign(ctx.T.user, { stamp: result.stamp, _version: result._version });
        setUser(ctx.T.user);
      } catch (err) { console.log(err.message)}
      ctx.T.alert("Data Saved");
    }
    setContext({...ctx});
  }
  //======================================================
  function textField(label,name,width="default",disabled=false) {
    return <TextField variant="outlined" label={label} disabled={disabled}  size="small" name={name} value={user[name]} onChange={handleChange}
      sx={{width:width}}
    />
  }
  //======================================================
  function calcExpiry() {
    if (ctx.T.isEmpty(ctx.T.user.expires)) {
      if (ctx.T.user.paid) return { cancelable: false, str: "active" };
      else return { cancelable: false, str: "Not subscribed" } 
    } else {
     if (Date.now() < ctx.T.user.expires) {
        let expiration = ctx.T.shortDate(ctx.T.user.expires);
        if (ctx.T.user.monthly) return { cancelable: true, str: expiration }; else return { cancelable: false, str: expiration };
      } 
    }
    return { cancelable: false, str: "Expired" }
  }
  //======================================================

  return (<>
    <Box sx={{display:"flex", flexDirection:"row", px:2}}>
      <Box component="h2" sx={{ flexGrow:1}}>Account Subscriber Details</Box>
      <Button variant="contained" sx={{my:3}} onClick={saveUser}>Save</Button>
    </Box>
    <Stack direction={{ xs:"column", md:"row" }} justifyContent="center">
      <Box>
        <table sx={{width:"100%",py:2}}>
          <tbody><tr>
            <td>Email/User ID:</td>
            <td><Box sx={{ ml: 3, backgroundColor: "#eee" }}>{user.email}</Box></td>
            <td style={{ textAlign: "right" }}><Button onClick={() => navigate('/login', { state: { formType: "Reset" } })}>Change Password</Button></td>
          </tr><tr>
            <td>Subsription Expires:</td>
            <td><Box sx={{ ml: 3, backgroundColor: "#eee" }}></Box></td>
            {/* <td style={{textAlign:"right"}}><Button>Extend Subscription</Button></td> */}
            </tr>
            {ctx.T.user.canPage && <tr>
              <td>Landing Page</td>
              <td>
                <RadioGroup row name="landingPage"  sx={{ml:3}} defaultValue="scheduleList" value={user.landingPage} onChange={handleChange}>
                  <FormControlLabel value="scheduleList" control={<Radio />} label="List of Schedules" />
                  <FormControlLabel value="paging" control={<Radio />} label="Paging Service" />
                </RadioGroup>
              </td>
              </tr>}
          </tbody>
        </table>
        <br />

        <Stack spacing={1} direction="row" sx={{m:1}}>
          {textField("Salutation","salutation",85)}
          {textField("First Name","firstName",150)}
          {textField("Middle Name","middleName",130)}
          {textField("Last Name","lastName",150)}
          {textField("Suffix","suffix",92)}
        </Stack>
        <Stack spacing={1} direction="row" sx={{m:1}}>
          {textField("Title","title",649)}
        </Stack>
        <Stack spacing={1} direction="row" sx={{m:1}}>
          {textField("Organization","organization",649)}
        </Stack>
        <Stack spacing={1} direction="row" sx={{m:1}}>
          {textField("Address","street",466)}
          {textField("Suite","suite",175)}
        </Stack>
        <Stack spacing={1} direction="row" sx={{m:1}}>
          {textField("Address Second Line","street2",649)}
        </Stack>
        <Stack spacing={1} direction="row" sx={{m:1}}>
          {textField("City","city",358)}
          {textField("State","state",75)}
          {textField("Zip","zip",200)}
        </Stack>
        <br/>
        <Stack spacing={1} direction="row" sx={{m:1,pb:2}}>
          {textField("Phone","phone",649)}
        </Stack>
        <br/>
        {/* <Stack spacing={1} direction="row" justifyContent="center" sx={{m:1,pb:2}}>
          <Button variant="contained" color="error">Cancel Subscription</Button>
        </Stack> */}
      </Box>
  </Stack>
  </>)}

User.defaultProps = {

};

export default User;