import { Box, Stack, Link } from '@mui/material';
import DatePicker, {Calendar, DateObject} from "react-multi-date-picker";
import { useState, useContext } from 'react';
import { useLocation} from 'react-router-dom';
import { GlobalContext } from '../App';
import MonthGrid from './MonthGrid';

//********************************************************************************************************
function Month(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  if (typeof ctx.schedule.assignments !== "object") ctx.schedule.assignments = {};
  if (typeof ctx.schedule.info !== "object") ctx.schedule.info = {};
  if (!Array.isArray(ctx.schedule.staff)) ctx.schedule.staff = [];
  if (!Array.isArray(ctx.schedule.activities)) ctx.schedule.activities = [];
  if (typeof ctx.schedule.notes !== "object") ctx.schedule.notes = {};

  let [state, setState] = useState({ month: (new Date().getMonth()), grid: "monthly", condensed: false, edit: false });
  const [showStats, setShowStats] = useState(false);
  let location = useLocation();
  try { props = { ...props, ...location.state } } catch (err) { }
  
  //======================================================
  function stats() {
    if (showStats === false) setShowStats(ctx.U.getStats()); else setShowStats(false);
  }
  //======================================================
  function getRowHeaders() {
    let ar = ctx.schedule.activities.map(act => act.name);
    ar.push("Vacations");
    return ar;
  }
  //======================================================
  function getYear() {
    let year = false;
    try {
      let startDate = new DateObject(ctx.schedule.info.startDate);
      year = startDate.year;
    } catch (err) { }
    return year;
  }
  //======================================================
  function createContent(editable) {
    let objContent = {};
    let names = new Set();
    for (let act of ctx.schedule.activities) {
      for (let doy = 1; doy <= 366; doy++) {
        try {
          let p = ctx.U.personFromGUID(ctx.schedule.assignments[act.guid][doy]);
          if (p) names.add(state.condensed ? p.shortName : p.name);                 // for hilite purposes
          objContent[act.name + "-" + doy] = state.condensed? p.shortName : p.name;
        } catch(err) {objContent[act.name + "-" + doy] = ""}
      }
    }
    // Add vacations
    for (let doy = 1; doy <= 366; doy++) {
      objContent["Vacations-" + doy] = <ctx.U.VacationPopup away={ctx.schedule.vacations[doy] ??= []} doy={doy} />
    }
    // Add names for hilite purposes
    let arNames = [];
    names.forEach((n => arNames.push(n)));
    arNames.sort();
    arNames.unshift("None");
    objContent["names"] = arNames;

    return objContent;
  }
  //======================================================

   return (<>
    {showStats && <div>
      <Stack direction="row" justifyContent="space-between" sx={{mt:1}}>
        <Box component="h2" sx={{ ml: 1 }}>Stats</Box>
        <Link href="#" sx={{ mt:1, mr:4 }} onClick={stats}>Close</Link>
      </Stack>
      <div dangerouslySetInnerHTML={{__html:showStats}}/>
    </div>}
    {!showStats && <>
      <Stack direction="row" justifyContent="flex-end"><Link href="#" sx={{ mt: 1, mr:4}} onClick={stats}>Stats</Link></Stack>
      <MonthGrid 
        rowHeaders={getRowHeaders()}
        condensed={state.condensed}
        edit={false}
        holidays={ctx.U.calcHolidays()}
        content={createContent(state.edit, state.condensed)}
        year={getYear()}
      /></>}

    <style> {`
      #statsTable td {
        padding-left:25px;
      }
    `}</style>
  </>)
}
Month.defaultProps = {
  bkColor: "#008ae6",
  company: " AMC Logic, LTD"
}

export default Month;