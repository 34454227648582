import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, Stack} from '@mui/material';
import { useContext } from 'react';
import { GlobalContext } from '../App';
import pager2 from '../images/pager2.png';


//********************************************************************************************************
function Topbar(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  let navigate = useNavigate();
  
  return (
    <AppBar position="sticky">
    <Toolbar className="w3-white" sx={{height:90}} position="sticky">
      <Stack direction="row" sx={{ width:0.3, textAlign: "left" }}>
        <img src={pager2} alt="" style={{ width: 90, marginLeft: -20}} />
        <Box component="h2" alignSelf="center" sx={{color:props.colorSubtitle}} >AMC Logic</Box>
      </Stack>
      <Stack sx={{width:0.4, alignItems:"center"}} ><>
        <Box component="h1" sx={{m:0, color:props.titleColor}}>Call Schedule</Box>
        <Box component="h3" sx={{m:0, color:props.colorSubtitle}}>https://callschedule.us</Box></>
      </Stack>
      {/* <Box sx={{ width: 0.3, textAlign: "right" }}>{ctx.T?.user?.firstName ? <Box>Welcome {ctx.T?.user?.firstName}</Box> : <Button className="w3-white" onClick={() => navigate("/login")} size="small">Login</Button>}</Box> */}
    </Toolbar>
      <Box className={props.colorClass2} sx={{height:4}}></Box>
  </AppBar>);
}
//********************************************************************************************************
Topbar.defaultProps = {
  colorClass1: "w3-metro-red",
  colorClass2: "w3-metro-red",
  titleColor: "#ee1111",
  colorSubtitle: "black",
}


export default Topbar;
