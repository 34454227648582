import {
  Box, Grid, Stack, Button, Tooltip, FormGroup, FormControlLabel, FormControl, Checkbox, Select, 
  Container, List, ListItemText, ListItemButton, Divider, TextField, InputLabel, MenuItem
} from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../App';
import { Calendar, DatePicker, DateObject } from "react-multi-date-picker";
import SortableList from './SortableList';

//********************************************************************************************************
let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
let sortOrder = 1;

//********************************************************************************************************
function Staff(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  ctx.schedule.staff ||= [];

  let [staff, setStaff] = useState(ctx.schedule.staff);
  let [selectedIndex, setSelectedIndex] = useState(ctx.schedule.staff.length>0? 0 :-1);

  const panelDetails = {
    shortName: "", fullName: "", providerType:"", role:"", cellPhone: "", officePhone: "", homePhone: "", pagerAppPIN: "", email: "",employeeID:"",providerNo:"",
    Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: true, Sunday: true,
    vacations:[], canDo:{}, frequency: "", howToCall:"", notes:"", 
  }

  //======================================================
  function handleChange(event) {
    if (selectedIndex < 0) return;
    if (Array.isArray(event)) {
      staff[selectedIndex].vacations = event;
    } else if (event.preventDefault) {
      event.preventDefault();
      try {
        let obj = staff[selectedIndex];
        if (event.target.type === "checkbox") {
          obj[event.target.name] = !!! obj[event.target.name];
        } else obj[event.target.name] = event.target.value;
      } catch (err) { }
    }
    ctx.schedule.staff = [...staff];
    setStaff(ctx.schedule.staff);
  }
  //======================================================
  function handleCanDo(event) {
    if (selectedIndex < 0) return;
    if (event.target.type !== "checkbox") return;
    event.preventDefault();
    try {
      let obj = staff[selectedIndex];
      let canDo = {}; ctx.schedule.activities.forEach(item => canDo[item.guid] = false);
      canDo = Object.assign(canDo, obj.canDo);
      canDo[event.target.name] = !!!canDo[event.target.name];
      obj.canDo = canDo;
    } catch (err) { console.log(err.message) }
  
    ctx.schedule.staff = [...staff];
    setStaff(ctx.schedule.staff);
  }
  //======================================================
  function newStaff(name) {
    return {...panelDetails, name:name, guid: ctx.T.guid()};
  }
  //======================================================
  function findIndex(obj) {
    return ctx.schedule.staff.findIndex((x => JSON.stringify(obj.guid) == JSON.stringify(x.guid)));
  }
  //======================================================
  function showDetail(obj, index, ar) {
    try {
      for (const [key, value] of Object.entries(panelDetails)) { if (typeof obj[key] === "undefined") obj[key] = value; };  // adding any missing keys from panelDetails
      ctx.schedule.staff = ar;
      ctx.schedule.staff[index] = obj;
      setSelectedIndex(index);
      setStaff([...ctx.schedule.staff]);
    } catch (err) { }
  }
  //======================================================
  function staffName() {
    try {
      return staff[selectedIndex].name;
    } catch (err) { }
    return "";
  }
  //======================================================
  function isChecked(checkName) {
    try {
      return !! staff[selectedIndex][checkName];
    } catch (err) { }
    return false;
  }
  //======================================================
  function isCanDo(checkGUID) {
    try {
      return !!staff[selectedIndex].canDo[checkGUID];
    } catch (err) { }
    return false;
  }
  //======================================================
  function newRow(label, dataSlug, minRows = 0, label2 = false, dataSlug2 = false) {
    return (<>
      <Grid item xs={3}>{label}</Grid>
      <Grid item xs={label2===false?9:3}><TextField variant="outlined" size="small" multiline={minRows > 0 ? true : false} minRows={minRows}
        name={dataSlug} value={staff[selectedIndex][dataSlug]}
        sx={{ width: "100%" }} onChange={handleChange}/>
      </Grid>
      {label2 && <>
        <Grid item xs={dataSlug2===false?6:2} sx={{textAlign:'right',pr:0.5}}>{label2}</Grid>
        {dataSlug2 && <Grid item xs={4}><TextField variant="outlined" size="small" multiline={minRows > 0 ? true : false} minRows={minRows}
          name={dataSlug2} value={staff[selectedIndex][dataSlug2]}
          sx={{ width: "100%" }} onChange={handleChange} />
        </Grid>}
      </>}
    </>)
  }
  //======================================================
  useEffect(() => {
    if (selectedIndex < 0) return;
    showDetail({},selectedIndex);
    ctx.schedule.staff = staff;
  },[staff.length]);
  //======================================================
  

  return (<>
    <Box sx={{display:"flex", flexDirection:"row", px:2}}>
      <Box component="h2" sx={{ flexGrow:1}}>Staff List</Box>
    </Box>
    <Stack direction="row" alignItems="flex-start" sx={{p:2}}>
      <Container sx={{ width: "300px" }}>
        <SortableList items={staff} nameKey="name" showButtons newItem={newStaff} classSel="w3-flat-clouds" classUnsel="w3-white" initialSelection={0} onSelected={showDetail}
          sx={{ height: 600, width: 170, border: "1px solid", p: 0 }} />
      </Container>
      {selectedIndex < 0 && <Grid justifyContent="center" className="w3-sand" container sx={{p:4 }}>
        <h3>No Staff Information on File.  Click
          <Box component="i" className="fa fa-plus-circle" sx={{ mx: 1, fontSize: "1.5rem", color: "#00A170" }} /> on the left to add
        </h3>
      </Grid>}
      {selectedIndex >= 0 &&<Grid container spacing={1} sx={{ border: 1, pr: "10px" }}>
        <Grid item xs={3} sx={{mt:0.5}}>Staff Person</Grid>
          <Grid item xs={2} sx={{mt:0.5,color:"blue",fontWeight:'bold'}}>{staffName()}</Grid>
          <Grid item xs={2} sx={{textAlign:'right',pr:0.5,mt:0.5}}>Credentials</Grid>
          <Grid item xs={2}><FormControl fullWidth>
            <Select name="providerType" value={staff[selectedIndex].providerType || ""} variant="standard" onChange={handleChange}>
              <MenuItem value="">&nbsp;</MenuItem>
              <MenuItem value="MD">MD</MenuItem>
              <MenuItem value="MD PhD">MD PhD</MenuItem>
              <MenuItem value="DO">DO</MenuItem>
              <MenuItem value="MBBS">MBBS</MenuItem>
              <MenuItem value="DPM">DPM</MenuItem>
              <MenuItem value="NP">NP</MenuItem>
              <MenuItem value="PA">PA</MenuItem>
              <MenuItem value="CRNA">CRNA</MenuItem>
            </Select>
          </FormControl></Grid>
          <Grid item xs={1} sx={{textAlign:'right',pr:0.5,mt:0.5}}>Role</Grid>
          <Grid item xs={2}><FormControl fullWidth>
            <Select name="role" value={staff[selectedIndex].role || ""} variant="standard" onChange={handleChange}>
              <MenuItem value="">&nbsp;</MenuItem>
              <MenuItem value="Attending">Attending</MenuItem>
              <MenuItem value="Fellow">Fellow</MenuItem>
              <MenuItem value="Resident">Resident</MenuItem>
              <MenuItem value="NP">NP</MenuItem>
              <MenuItem value="PA">PA</MenuItem>
            </Select>
          </FormControl></Grid>

          {newRow("Initials / Short Name", "shortName")}
          {newRow("Full Name", "fullName")}
          {newRow("Employee ID", "employeeID", 0, "Provider No", "providerNo")}
          {newRow("E-mail", "email")}
          {newRow("Cell Phone", "cellPhone", 0, "Office Phone", "officePhone")}
          {newRow("PagerApp PIN", "pagerAppPIN", 0, "Home Phone", "homePhone")}
          <Grid item xs={3}>Availability</Grid>
          <Grid item xs={9}>
            <FormGroup row sx={{ width: "90%", borderTop: "1px solid silver" }}>
              {weekDays.map((day, index) => <FormControlLabel control={<Checkbox value={true} checked={isChecked(day)} name={day} onClick={handleChange} />} key={day} label={day} />)}
            </FormGroup>
          </Grid>

          <Grid item xs={3}>Vacations</Grid>
          <Grid item xs={9}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", padding: "1rem", width: "90%", borderTop: "1px solid silver" }}>
              <Calendar
                numberOfMonths={2}
                multiple
                disableMonthPicker
                currentDate={new DateObject().setYear(ctx.schedule?.info?.startDate?.substring(0, 4) || new Date().getFullYear())}
                value={staff[selectedIndex].vacations}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          {newRow("How to Call", "howToCall", 3)}
          {newRow("Notes", "notes", 3)}
          <Grid item xs={12}>&nbsp;</Grid>
      </Grid>}
      {selectedIndex >= 0 && <Grid container spacing={1} sx={{ width: "290px", ml: 1, pl: 2, border: 1 }}>
        <FormGroup >
          <h4>Duties</h4>
          {ctx.schedule.activities.map((activity, index) => <FormControlLabel control={<Checkbox value={true} color="success"
            checked={isCanDo(activity.guid)}
            name={activity.guid} onClick={handleCanDo} />} key={activity.guid} label={activity.name} />)}
        </FormGroup>
      </Grid>}
    </Stack>
    <p /> 
  </>)
}

Staff.defaultProps = {
  bkColor: "#008ae6",
  company: " AMC Logic, LTD"
}

export default Staff;
