import { Card, CardActionArea, CardActions, CardContent, CardMedia, List, ListItem, ListItemText, Divider, Button } from '@mui/material';

function BSCard(props) {

  return (<>
    <Card className="root">
        <CardMedia className="media" image={props.image}/>
        <CardContent>
          <h4>{props.title}</h4>
          <h5>{props.subtitle}</h5>
        </CardContent>
      <List>
        {props.listItems.map((item) => <div key={item}><Divider /><ListItem><ListItemText>{item}</ListItemText></ListItem></div>)}
        {props.links.length > 0 && <Divider/>}
      </List>
      <CardActions>
        {props.links.map((item) => <Button className="grow"  color="primary" href={item.href} key={item.text}>{item.text}</Button>)}
      </CardActions>
    </Card>
    
    <style>{`
      .root {
        max-width: 18rem;
      }
      .media {
        height: 220px;
      }
      .grow {
        flex-grow:1;
      }
    `}</style>
  </>)
}

BSCard.defaultProps = {
  width: "18rem",
  links: [],
  listItems: [],
};

export default BSCard;