import { Paper, Box, Button, Container } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BSCard from './BSCard'
import { useContext } from 'react';
import { GlobalContext } from '../App';
import image1 from '../images/desk.png'
import image2 from '../images/app.png'
import image3 from '../images/bulbs.png'


function Home(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  let navigate = useNavigate();

  return (<>
    <Box m={5} p={3} pb={5} component={Paper} elevation={3}>
      <Box component="h2" my={2} color="primary.main">Bring On-line Convenience to Your Call Schedule</Box>
      <ul style={{ marginRight: "2%", marginLeft: "2%" }}>
        <li><p style={{ marginLeft: 10, fontSize: "14pt", lineHeight: "2rem" }}>Use our Online Portal to make and publish your call schedule to provide an easily accessible, accurate call schedule 100% of the time to all of your users</p></li>
        <li><p style={{ marginLeft: 10, fontSize: "14pt", lineHeight: "2rem" }}><b>Professional to Professional Use:</b> You can include individualized instructions, contact information and notes about how to contact the on-call person. This method works well for receiving messages from other professionals, like hospital nurses or the answering service. </p></li>
        <li><p style={{ marginLeft: 10, fontSize: "14pt", lineHeight: "2rem" }}><b>Public to Professional Use:</b> When integrated with Pager iPhone App, CallSchedule portal can be set up to automatically direct messages to the on-call person from your public website, without revealing your contact information. <b className="w3-text-green">This can eliminate the need for an answering service</b></p></li>
        <li><p style={{ marginLeft: 10, fontSize: "14pt", lineHeight: "2rem" }}>Used by medical and other professionals.</p></li>
        <li><p style={{ marginLeft: 10, fontSize: "14pt", lineHeight: "2rem" }}>Cost Effective:  Free 3 month Trial.  Then $200 per year.</p></li>
        <hr className="my-4" />
        <p>Using an online schedule as the "Source of Truth" avoids missed calls. </p>
        <p>When the schedule changes, the changes can be published through the web portal from anywhere, keeping the online schedule accurate</p>

        {ctx.T.isBlank(ctx.user) ? <Button variant="contained" color="primary" size="lg" onClick={() => navigate("/login", { state: { formType: "Register" } })} >Create Account</Button>
          : ctx.user.paid ? "" : <Button variant="contained" color="primary" size="lg" onClick={() => navigate("/payment")} >Buy Full Access (just $29)</Button>
        }
      </ul>
    </Box>

    {ctx.T.isBlank(ctx.user) && <Box className="w3-light-blue" width="50%" mx="auto" p={2} textAlign="center">
      <h3>Free iPhone App to View Schedules</h3>
      <p><a href="https://apps.apple.com/us/app/call-schedule/id335902744">Download App</a></p>
    </Box>}

    <Box my={5} pt={2} display="flex" justifyContent="space-evenly" >
      <BSCard image={image1} title="ON-LINE CONVENIENCE" subtitle=""
        listItems={["Use Any Modern Web Browser", "Make the Schedule OnLine Using a Provided Secure Portal", "Publish to Your Audience Online","Published Schedule can be Freely Updated As Needed"]}
      />
      <BSCard image={image2} title="PUBLISHED SCHEDULES CAN BE PASSWORD PROTECTED" subtitle=""
        listItems={["All Published Schedules are View-Only","Published with Simple, Advanced or No Password Protection", "Free iOS App for Viewing Published Schedules"]}
      />
      <BSCard image={image3} title="THREE MONTHS FREE TRIAL" subtitle=""
        listItems={["$200 per Published Schedule per Year After the Free Trial Ends", "Technical Support (Rarely Needed) $100 per hour","Available Full Service Schedule Creation and Maintenence.  Prices are Customized"]}
      />
    </Box>

  </>)
}

Home.defaultProps = {
  width: "18rem"
};

export default Home;