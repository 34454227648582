import { Box, Stack, Button, Paper, Tooltip, Container, TextField } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { ReactSortable } from "react-sortablejs";
import { GlobalContext } from '../App';

//********************************************************************************************************
function SortableList(props) {
  const [items, setItems] = useState(props.items || []);
  const [sortOrder, setSortOrder] = useState(1);
  const [lastIndex, setLastIndex] = useState(-1);
  const [selected, setSelected] = useState(false);
  let [ctx, setContext] = useContext(GlobalContext);

  //======================================================
  function handleChange(item,index) {
    try { props.onSelected(item ?? {}, index, items); } catch (err) { }
    setLastIndex(index);
    setSelected(item);
  }
  //======================================================
  async function newItem() {
    let answer = await ctx.T.prompt("Please enter a name for the new Item","Add");
    if (ctx.T.isEmpty(answer)) return;
    let obj = {}; obj[props.nameKey] = answer;
    try { obj = props.newItem(answer) } catch (err) { }
    let newIndex = lastIndex + 1;
    items.splice(newIndex, 0, obj);
    setLastIndex(newIndex);
    setItems([...items]);
    handleChange(obj, newIndex);
  }
  //======================================================
  async function deleteItem() {
    if (lastIndex >= 0) {
      let yes = await ctx.T.confirm(`Do you want to permanently delete "${ selected[props.nameKey] }" and all associated data ?`);
      if (!yes) return;
      items.splice(lastIndex, 1);
      setItems([...items]);
      if(items.length <= 0) setLastIndex(-1); else handleChange(items[0],0)
    } else ctx.T.alert("No Item Selected");
  }
  //======================================================
  async function editName() {
    try {
      let answer = await ctx.T.prompt("", "Edit", selected[props.nameKey]);
      if (ctx.T.isEmpty(answer)) return;
      selected[props.nameKey] = answer;
      setItems([...items]);
    } catch(err){}
  }
  //======================================================
  function sortNames() {
    try {
      items.sort((a, b) => {
        try {
          let fa = a.name.toLowerCase();
          let fb = b.name.toLowerCase();
          if (fa < fb) return -sortOrder;
          if (fa > fb) return sortOrder;
        } catch(err) {}
        return 0;
      })
      setItems([...items]);
      // sortOrder *= -1;
      setSortOrder(sortOrder * -1);
    } catch(err){}
  }
  //======================================================
  function dragEnded(drag) {
    setLastIndex(drag.newIndex);
    ctx.T.moveArrayItem(items, drag.oldIndex, drag.newIndex);
  }
  //======================================================
  function findChild(obj, str=false) {
    try {
      let ar = str.split(".");
      let out = obj;
      for (let i = 0; i < ar.length; i++) out = out[ar[i]];
      return out;
    } catch (err) { }
    return obj;     // if there is no nameKey obj is treated as a string
  }
  //======================================================
  function selectionExists() {
    try { return items.findIndex(x => x == selected) >= 0 } catch (err) { }
    return false;
  }
  //======================================================
  useEffect(() => {
    try { props.onReset?.() } catch (err) { } // run function requested by parent
  },[items.length]);
  //======================================================
  useEffect(() => {
    setItems([...props.items]);
    // try { if (! selectionExists()) handleChange(props.items[0], 0); } catch(err) {}  // creates infinite loop
    try {
      if (props.initialSelection >= 0 && props.initialSelection < props.items.length && !selectionExists())
        setSelected(props.items[props.initialSelection]);
    } catch (err) { }
  },[props.items]);
  //======================================================

  return (<Stack sx={props.sx}>
    {props.showButtons && <Stack direction="row" justifyContent="space-between" sx={{ mb: 0, p:0.7, fontSize: "1.7rem", borderBottom:"1px solid"}} className="w3-sand">
      <Tooltip title="Add New" placement="top">
        <Box component="i" className="fa fa-plus-circle" sx={{ pr: 2, color: "#00A170", cursor: "pointer" }} onClick={newItem} />
      </Tooltip>
      <Tooltip title="Edit" placement="top">
        <Box component="i" className="fa fa-pencil-square-o" sx={{ pr: 2, color: "#00A170", cursor: "pointer" }} onClick={editName} />
      </Tooltip>
      <Tooltip title="Sort Alphabetically" placement="top">
        <Box component="i" className="fa fa-sort" sx={{ pr: 2, color: "#00A170", cursor: "pointer" }} onClick={sortNames} />
      </Tooltip>
      <Tooltip title="Delete" placement="top">
        <Box component="i" className="fa fa-trash" sx={{ color: "red", cursor: "pointer" }} onClick={deleteItem} />
      </Tooltip>
    </Stack>}
    <Box sx={{ fontWeight: "bold", ml: 0.2 }}>{props.title}</Box>
    <Box  sx={{overflow: "auto"}}>
      <ReactSortable list={items} setList={setItems} animation={200} onEnd={dragEnded}>
        {items.map((item, index) => {
          return (
            <Box sx={{ cursor: "pointer", border: "1px solid lightgray", px: 1, py: 0.5 }}
              className={item == selected ? props.classSel : props.classUnsel}
              key={item?.guid || "g"+index}
              onMouseDown={() => handleChange(item,index)}>
              {findChild(item, props.nameKey)}
            </Box>
          )
        })}
      </ReactSortable>
    </Box>
  </Stack>);

}
//********************************************************************************************************
SortableList.defaultProps = {
  classSel: "w3-flat-clouds",
  classUnsel: "w3-white",
}
export default SortableList;
