import { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Link, TextField, IconButton, InputAdornment } from '@mui/material';
import { GlobalContext } from '../App';
import { css } from '@emotion/react';

//********************************************************************************************************
function Password(props) {
  const [show, setShow] = useState(false);
  function handleshowPassword(event) {
    event.preventDefault();
    setShow(!show);
  }
  return (<TextField sx={textField} type={show ? "text" : "password"} size="small" variant="outlined"
      name={props.name} value={props.value} onChange={props.onChange} label={props.label} required
      InputProps={{
        endAdornment: < InputAdornment position="end">
          <IconButton onClick={handleshowPassword} edge="end">{show ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye"  />}</IconButton>
        </InputAdornment>
      }}
    />)
}
Password.defaultProps = {
  label: "Password",
  name: "password",
}
//********************************************************************************************************
function FancyButton (props) {
  return <Button sx={{
  transition: 'all .5s ease',
  width: '70%',
  borderRadius: '30px',
  color: props.bkColor,
  fontWeight: "600",
  backgroundColor: '#fff',
  borderColor: props.bkColor,
  border: '1px solid',
  marginTop: '1.5em',
  marginBottom: '1em',
  padding: '0.5em',
  '&:hover':{
    backgroundColor: props.bkColor,
    color:'#fff'
  },
  '&:focus': {
    backgroundColor: props.bkColor,
    color:'#fff'
  },
  ...props.sx}} onClick = { props.onClick } > { props.children }</Button>
}
//********************************************************************************************************


//********************************************************************************************************
function Login(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  let navigate = useNavigate();
  let location = useLocation();
  let login = new URLSearchParams(location.search);
  let email= login?.get("email") || "", password= login?.get("password") || "";
  
  try { props = { ...props, ...location.state } } catch (err) { }

  const [state, setState] = useState({
    formType: props.formType,
    showPassword: false,
    intro: props.intro[props.formType],
    email: email,
    password: password,
  });
  //======================================================
  function keyDown(event) {
    if (event.code !== "Enter") return;
    event.preventDefault();
    switch (state.formType) {
      case "Register": register(); break;
      case "Login": signin(); break;
      case "Forgot": forgot(); break;
      case "Reset": changePassword(); break;
      default: break;
    }
  }
  //======================================================
  function handleChange(event) {
    event.preventDefault();
    setState({...state, [event.target.name] : event.target.value});
  }
  //======================================================
  function showForm(frm) {
    setState({formType: frm, intro: props.intro[frm] });
  }
  //======================================================
  async function register(event) {
    try { event.preventDefault() } catch (err) { }

    var [_, result] = await ctx.T.post("register", { firstName: state.firstName, lastName: state.lastName, email: state.email });
    if (result == 1) {
      showForm("Login");
      ctx.T.alert("Password Sent", "Please check your email.  A temporary password has been sent to " + state.email);
    }
    else if (result == "exists") {
      let yes = await ctx.T.confirm("Account Already Exists", "Do you want to reset your Password?", "Send New Password", "Cancel");
      if (yes) forgot(false);
    }
    else if (result == "not found") ctx.T.alert("Error - User Not Found", `We could not find a user registered with "${ state.email }" email.`);
    else ctx.T.alert("Error", "Please try again later");
  }
  //======================================================
  async function forgot(confirm = true) {
    if (confirm) {
      let confirmed = await ctx.T.confirm("Do you want to reset your password?  A temporary password will be emailed to you", "Forgot Password");
      if (!confirmed) return;
    }
    if (!ctx.T.isEmail(state.email)) { ctx.T.alert("Please enter a valid email address as your user name so that a new password can be sent to you", "Username Required"); return; }
    var [_, result] = await ctx.T.post("forgot", {email:state.email});
    result = !!parseInt(result);
    if (result) { ctx.T.alert(`New temporary password sent to ${state.email}<br><br>Please check your SPAM/Junk Mail folder too :)`, "Success"); showForm("Login"); }
    else ctx.T.alert("Please try again later", "Something Went Wrong");
  }
  //======================================================
  async function changePassword(event) {
    try { event.preventDefault() } catch (err) { }
    if (!ctx.T.isEmail(state.email)) { ctx.T.alert("Valid email required", "Invalid Email"); return; }
    if (state.newpassword !== state.newpassword2) { ctx.T.alert("Both entries of new passwords do not match.  Please try again", "Mismatch"); return; }
    let data = { email: state.email, oldpassword: state.oldpassword, newpassword: state.newpassword };
    var [result, _] = await ctx.T.post("changePassword", data);
    let success = false; try { if (result.nModified > 0) success = true; } catch (err) { }
    
    if (success) {
      ctx.T.alert("Password updated.  Please Sign-in", "Success");
      setState({ ...state, password: "", newpassword: "", newpassword2: "" });
      showForm("Login");
    }
    else ctx.T.alert("Could not update password.  Please try again!", "Something Went Wrong");
  }
  //======================================================
  async function signin() {
    let [user, result] = await ctx.T.post("checkPassword", state);

    try {
      user._id ||= "";
      if (user._id.length > 10) result = "success";
    } catch(err) {result = "fail"}

    switch (result) {
      case "reset": showForm("Reset"); showForm("Reset");  break;
      case "fail": ctx.T.alert("Could not Sign in", "Invalid Password or User name"); break;
      case "Not Found": showForm("Register"); ctx.T.alert("User Not Found", "Please create your account"); break;
      case "success": try {
        ctx.T.user = { ...user, pwd: state.password };  // used in _T.post
        // if (ctx.T.isEmpty(obj.expires)) obj.expires = 0;
        // if (obj.expires < Date.now()) obj.paid = false; else obj.paid = true;
        // if (!obj.paid && obj.expires > 0) ctx.T.paymentExpired = true; 
        //   else ctx.T.paymentExpired = false;    // never paid - so not really expired - used in Site.js
        // if (obj._id) {
        //   // if (obj.paid) navigate("/maketest"); else navigate("/payment");
        //   if (obj.paid) navigate("/maketest"); else navigate("/testlist");
        // }
        // Choose Landing Page
        if (user.pagingOnly) navigate("/pager"); 
        else try {  
          let [scheduleList, _] = await ctx.T.post("scheduleList");
          let [pagingList, __] = await ctx.T.post("pagingList");
          ctx.T.user.canPage = pagingList.length > 0;
          if (ctx.T.user.landingPage === "paging") navigate("/pager");
          else if (ctx.T.user.landingPage === "scheduleList") navigate("/schedules");
          else if (scheduleList.length > 0) navigate("/schedules");
          else if (pagingList.length > 0) navigate("/pager"); 
          else navigate("/schedules");
        } catch (err) { navigate("/schedules")}
      } catch (err) { }
        break;
      default: navigate("/home"); 
    }
  }
  //======================================================
  ctx.T.user = {};
  ctx.schedule = {}

  if (email && password) signin(); else

  return (<>
    <Box display="flex" sx={main_content} onKeyDown={keyDown}>
      <Box style={{backgroundColor:props.bkColor}} sx={company__info} >
        {/* <props.icon sx={{ fontSize: "9rem", mx:'auto' }} /> */}
        <img src={props.image} alt="" style={{ width: "90%", mx:"auto"}} />
        <div style={{fontSize: "1.2rem",marginTop:20}}>{props.company}</div>
      </Box>
      <Box width="70%" display="flex" py={2} flexDirection="column" className="col-md-8 col-sm-12">
        <Container>
          {state.formType === "Login" && <>
            <Box component="h2" sx={{ color: props.bkColor}}>Sign-In</Box>
            <TextField sx={textField} size="small" variant="outlined" name="email" value={state.email} onChange={handleChange} label="email" required style={{ marginBottom: "1.5em" }} />
            <Password value={state.password} name="password" onChange={handleChange} />
            <FancyButton bkColor={props.bkColor} onClick={signin}>Submit</FancyButton>
            <p>Don't have an account? <Link href="#" onClick={() => showForm('Register')}>Register Here</Link></p>
            <p><Link href="#" onClick={() => showForm('Forgot')}>Forgot Password</Link></p>
          </>}
          {state.formType === "Register" && <>
            <Box component="h2" sx={{ color: props.bkColor}}>Register</Box>
            <TextField sx={textField}  size="small" variant="outlined" name="firstName" value={state.firstName} onChange={handleChange} label="First Name" required />
            <TextField sx={textField}  size="small" variant="outlined" name="lastName" value={state.lastName} onChange={handleChange} label="Last Name" required />
            <TextField sx={textField}  size="small" variant="outlined" type="email" name="email" value={state.email} onChange={handleChange} label="email" required/>
            <p className="text-muted mb-3">A temporary password will be emailed to you</p>
            {/* {props.terms && <label><input type="checkbox" required /> Agree to  <Link href={props.terms} target="_blank">Terms & Conditions</Link></label>} */}
            <FancyButton bkColor={props.bkColor} onClick={register}>Register</FancyButton>
            <p>Already have an account? <Link href="#" onClick={() => showForm('Login')}>Login</Link></p>
            <p><Link href="#" onClick={() => showForm('Forgot')}>Forgot Password</Link></p>
          </>}
          {state.formType === "Forgot" && <>
            <Box component="h2" sx={{ color: props.bkColor}}>Forgot Password</Box>
            <TextField sx={textField}  size="small" variant="outlined" type="email" name="email" value={state.email} onChange={handleChange} label="email" required />
            <p className="text-muted">A temporary password will be emailed to you</p>
            <FancyButton bkColor={props.bkColor} onClick={forgot}>Get Password</FancyButton>
            <p>Already have an account? <Link href="#" onClick={() => showForm('Login')}>Login</Link></p>
            <p>Don't have an account? <Link href="#" onClick={() => showForm('Register')}>Register Here</Link></p>
          </>}
          {state.formType === "Reset" && <>
            <Box component="h2" sx={{ color: props.bkColor}}>Change Password</Box>
            <TextField sx={textField}  size="small" variant="outlined" type="email" name="email" value={state.email} onChange={handleChange} label="email" required />
            <Password value={state.password} name="oldpassword" onChange={handleChange} label="Current Password" />
            <Password value={state.newpassword} name="newpassword" onChange={handleChange} label="New Password" />
            <Password value={state.newpassword2} name="newpassword2" onChange={handleChange} label="Repeat New Password" />
            <FancyButton bkColor={props.bkColor} onClick={changePassword}>Save New Password</FancyButton>
          </>}
        </Container>
      </Box>
    </Box>
    {state.intro && <Box sx={bottomInfo}><Box sx={{p:3}}>{state.intro}</Box></Box>}
    <Box display="flex" justifyContent="center">&copy; {props.company}</Box>

  </>)
}

const main_content = css`
  width: '70%';
  max-width: 700px;
  border-radius: 20px;
  border-top:1px solid #ccc;
  box-shadow: 0 5px 5px rgba(0,0,0,.4);
  margin: 5em auto 2em;;
  text-align: center;
  display: flex;
  p {
    font-size: 12pt;
  }
`;
const textField = css`
  margin-bottom: 0.75em;
  width: 100%;
`;
const bottomInfo = css`
  width: 70%;
  max-width: 600px;
  border-radius: 20px;
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  margin: 5em auto 2em;;
  text-align: center;
`; 
const company__info = css`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
  width: 30%;
`;

Login.defaultProps = {
  formType: "Login",
  bkColor: "#008ae6",
  intro: { Login: "", Register: "", Forgot: "", Reset: "" },
  icon: "",
  terms: "",
  company: " AMC Logic, LTD"
}

export default Login;