import {
  Box, Grid, Stack, Button, Typography, Tooltip, FormGroup, FormControlLabel, Checkbox, ButtonGroup, Radio,
  Container, List, ListItem, ListItemText, ListItemButton, Divider, TextField, FormControl, RadioGroup, FormLabel
} from '@mui/material';
import WeekList from './WeekList.js';
import { useState, useEffect } from 'react';
import { GlobalContext } from '../App';

//********************************************************************************************************
let timeOptions = [
  { a: "07:00", b: "17:00" },
  { a: "17:00", b: "22:00" },
  { a: "17:00", b: "07:00" },
  { a: "07:00", b: "07:00" },
  { a: "", b: "" }
];
let tOptionNow= 0;
let dailyDetails = {};
//********************************************************************************************************
function DailyDetails(props) {
  const panelDetails = {
    startTime: props.activity.startTime, endTime: props.activity.endTime
  }
  props.activity.dailyDetails ||= {};
  // try { dailyDetails = { ...props.activity.dailyDetails } } catch (err) { }
  let ctx = props.ctx;
  let [selected, setSelected] = useState(0);
  let [details, setDetails] = useState(() => {
    dailyDetails = props.activity.dailyDetails;
    return dailyDetails[selected] || panelDetails;
  })

  //======================================================
  function handleChange(event) {
    if (selected < 0 && selected > 6) return;
    event.preventDefault();
    try {
      let obj = dailyDetails[selected] ||= panelDetails;
      if (event.target.type === "checkbox") {
        obj[event.target.name] = !!! obj[event.target.name];
      } else obj[event.target.name] = event.target.value;
      setDetails({ ...dailyDetails[selected] });
      props.receiveDetails(dailyDetails);
    } catch (err) { }
  }
  //======================================================
  function cycleTime() {
    let obj = dailyDetails[selected] ||= panelDetails;
    obj.startTime = timeOptions[tOptionNow].a;
    obj.endTime = timeOptions[tOptionNow].b;
    props.receiveDetails(dailyDetails);
    setDetails({...dailyDetails[selected]});
    tOptionNow += 1;
    if (tOptionNow >= timeOptions.length) tOptionNow = 0;
  }
  //======================================================
  function showDetail(dayNo) {
    try {
      tOptionNow = 0;
      selected = dayNo;
      dailyDetails[selected] ||= panelDetails;
      setSelected(dayNo);
      setDetails({...dailyDetails[selected]});
      // console.log(dailyDetails[selected]);
    } catch (err) { }
  }
  //======================================================
  useEffect(() => {
    showDetail(selected);
  }, [selected]);
  //======================================================


  return (<>
    <Stack direction="row" alignItems="flex-start" sx={{p:2}}>
    <List dense sx={{ border: 1, maxHeight:600, overflow:'auto', width:150}} >
        {["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Holidays"].map((day, index) => 
          <ListItemButton selected={selected == index} key={day} divider name={day} onClick={()=>showDetail(index)}>
            <ListItemText primary={day} />
          </ListItemButton>
        )}
    </List>
      {selected >= 0 && <Stack>
        <Stack direction="row" spacing={3} alignItems="flex-start" divider={<Divider orientation="vertical" flexItem />} sx={{ border: 0, p: 2 }}>
          <Grid container>
            <Grid item xs={3}>Duty Hours</Grid>
            <Grid item xs={9}>
              <TextField variant="outlined" size="small" type="time"  name="startTime" value={details?.startTime}
                onChange={handleChange} />
              <span style={{ margin: "12px", fontSize:"1.5rem"}}>to</span>
              <TextField variant="outlined" size="small" type="time" name="endTime" value={details?.endTime}
                onChange={handleChange} />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <Button sx={{ px:1, mb:2,fontSize: "0.9rem"}} onClick={cycleTime}>Commonly Used Shifts</Button>
            </Grid>

            <Grid item xs={12}>&nbsp;</Grid>
          </Grid>
        </Stack>
      </Stack>}
    </Stack>
      <p />
  </>)
}

export default DailyDetails;
