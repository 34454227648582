import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { Twitter, Facebook } from '@mui/icons-material';

export default function Footer(props) {
  let navigate = useNavigate();

  function link(slug) {
    navigate(slug);
  }
  return (<>
    <Grid container className="w3-metro-darken" style={{ padding: "20px", borderTop: "20px solid lightblue"}}>
      <Grid item xs={5} style={{paddingLeft:"3em"}}>
        <div className="faded">Notices</div>
        <Box pr={5} className="faded2">
          <div>All material on this website is the copyrighted property of AMCLogic, LTD.  Any unauthorized duplication or use is prohibited.</div>
          <div>Each registered user is granted a non-exclusive single person access to the materials only for official use.</div>
          <div>Sign-in credentials may not be shared.</div>
          <div>All services are provided "As-is" without any warranties, express or implied.</div>
        </Box>
      </Grid>
      <Grid item xs={2} style={{ paddingLeft: "3em" }}>
      </Grid>
      <Grid item xs={2} style={{ paddingLeft: "3em" }}>
      </Grid>
      <Grid item xs={3} style={{ paddingLeft: "3em" }}>
        <div className="faded">
          <div>Follow Us</div>
          <Link to="/"><Twitter /></Link>
          <Link to="/"><Facebook /></Link>
        </div>
      </Grid>
    </Grid>
    <center>
      <Box p={2} mt="1px" className="w3-metro-darken w3-text-gray">Copyright &copy; {new Date().getFullYear()} AMC Logic LTD. All Rights Reserved.</Box>
    </center>

    
    <style>{`
      .faded {
        color: #ccc;
      }
      .links-vertical {
        list-style-type: none;
      }
      .links-vertical li a {
        display: block;
        margin-left: -2.6em;
        margin-bottom: 0.7em;
        text-transform: uppercase;
        text-decoration: none;
        padding-right: 20px;
      }
      .faded2 {
        color: #888;
        font-size: 0.9rem;
      }
    `}</style>
  </>)
}

Footer.defaultProps = {

}