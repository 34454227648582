import { Box, Container, Stack, Link, Button, IconButton, ToggleButton, ToggleButtonGroup, NativeSelect, FormControl, FormControlLabel, Checkbox, TextField, RadioGroup,Radio  } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LockIcon from '@mui/icons-material/Lock';
import { useState, useContext, useEffect } from 'react';
import { useLocation} from 'react-router-dom';
import { GlobalContext } from '../App';
import MonthGrid from './MonthGrid';

let loadingMessage = <i className="fa fa-cog fa-spin fa-3x fa-fw w3-text-blue"></i>;

//********************************************************************************************************
async function dlgDailyNotes(ctx,doy,activityName,data={})
{
  let props = {
    title: `Notes for ${activityName} on ${ctx.T.dateFromDoy(doy,ctx.schedule.info.startDate.substring(0,4)).toString().substring(0,16)}`,
    handleOk: () => data = ctx.T.readForm("dlgForm"),
    message: <>
      <form id="dlgForm">
        <Stack direction="row" sx={{ my: 2 }} alignItems="center">
          <Box sx={{ width: 150 }}>Payment Rate</Box>
          <RadioGroup name="rate" defaultValue={data.rate ?? "stdRate"} row>
            <FormControlLabel value="stdRate" control={<Radio />} label="Standard" />
            <FormControlLabel value="specialRate" control={<Radio />} label="Special" />
            <FormControlLabel value="premiumRate" control={<Radio />} label="Premium" />
          </RadioGroup>
        </Stack>
        <Stack direction="row" sx={{ my: 2 }} alignItems="center">
          <Box sx={{ width: 150 }}>Hours Worked</Box>
          <TextField variant="outlined" size="small" name="hours" defaultValue={data.hours??"Full Shift"}/>
          <Box sx={{ ml: 1 }}>hrs</Box>
        </Stack>
        <Stack direction="row" sx={{ my: 2 }} alignItems="center">
          <Box sx={{ width: 150 }}>Notes</Box>
          <TextField variant="outlined" size="small" name="notes" multiline minRows={3} defaultValue={data.notes ?? ""} sx={{ width: "70%" }} />
        </Stack>
        <Stack direction="row" sx={{ my: 2 }} alignItems="center">
          <Box sx={{ width: 150 }}>Color&nbsp;Code</Box>
          <RadioGroup name="colorClass" defaultValue={data.colorClass ?? ""} row sx={{ml:9}}>
            <FormControlLabel value="" control={<Radio />} label="None" />
            <FormControlLabel value="w3-white" control={<Radio condensed />} label="White" />
            <FormControlLabel value="w3-green" control={<Radio />} label="Green" />
            <FormControlLabel value="w3-yellow" control={<Radio />} label="Yellow" />
            <FormControlLabel value="w3-blue" control={<Radio />} label="Blue" />
            <FormControlLabel value="w3-purple" control={<Radio />} label="Purple" />
            <FormControlLabel value="w3-brown" control={<Radio />} label="Brown" />
            <FormControlLabel value="w3-orange" control={<Radio />} label="Orange" />
            <FormControlLabel value="w3-pink" control={<Radio />} label="Pink" />
          </RadioGroup>
        </Stack>
      </form>
    
    </>
  }
  await ctx.T.dlg(props);
  return data;
}
//********************************************************************************************************
function Select(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  let [chosen, setChosen] = useState(() => {
    ctx.schedule.notes ||= {};
    ctx.schedule.notes[props.activity.guid] ||= {};
    ctx.schedule.notes[props.activity.guid][props.doy] ||= {}
    try { var curr = ctx.schedule.assignments[props.activity.guid][props.doy]; } catch (err) { var curr = ""; }
    return curr;
  });
  const [className, setClassName] = useState(props.className);
  const [locked, setLocked] = useState( !! ctx.schedule.notes[props.activity.guid][props.doy].locked);

  //======================================================
  function handleChange(event) {
    ctx.schedule.assignments[props.activity.guid] ??= {};
    ctx.schedule.assignments[props.activity.guid][props.doy] = event.target.value;
    setChosen(event.target.value);

    try {
      let p = false, fits = false;
      p = ctx.U.personFromGUID(event.target.value);
      fits = ctx.U.fits(props.activity, p, props.doy)[0];
      if (p === false) setClassName("w3-text-red"); else if (fits) setClassName(""); else setClassName("w3-2021-buttercream");
    } catch (err) { }
  }
  //======================================================
  function setLock(doy) {
    ctx.schedule.notes[props.activity.guid][props.doy].locked = !!!ctx.schedule.notes[props.activity.guid][props.doy].locked;
    setLocked(ctx.schedule.notes[props.activity.guid][props.doy].locked);
  }
  //======================================================
  async function callHistory(doy) {
    let [items, _] = await ctx.T.post("getCallHistory", { parentID: ctx.schedule._id, activityGUID: props.activity.guid, doy: doy })
    let str = "";
    try {
      for (let item of items) str += `${ctx.T.shortDate(item.date)}:&emsp;${item.person}<br/>`;
    } catch (err) { }
    let year = ctx.schedule.info.startDate.substring(0, 4);
    ctx.T.alert(str, `Call History for ${ ctx.T.dateFromDoy(doy, year)[1] }`);
  }
  //======================================================
  async function showConflicts() {
    if (props.tooltip) ctx.T.alert(props.tooltip, `Conflicts for ${ props.activity.name } on ${ ctx.T.dateFromDoy(props.doy, props.year)[1] }`)
  }
  //======================================================
  async function dailyNotes(doy, ev) {
    try { if (ev.shiftKey) return callHistory(doy)} catch(err) {}
    try { if (ev.ctrlKey) return setLock(doy) } catch (err) { }
    try { if (ev.altKey) return showConflicts() } catch (err) { }
    // ctx.schedule.notes ||= {};
    // ctx.schedule.notes[props.activity.guid] ||= {};
    // ctx.schedule.notes[props.activity.guid][props.doy] ||= {}
    try {
      if (locked) {
        ctx.T.alert("Use Ctrl-Click to unlock");
      } else {
        let data = await dlgDailyNotes(ctx, doy, props.activity.name, ctx.schedule.notes[props.activity.guid][props.doy]);
        if( ! ctx.T.isBlank(data)) ctx.schedule.notes[props.activity.guid][props.doy] = data;
        setClassName(data.colorClass);
      }
    } catch (err) { }
  }
  //======================================================
  function getHilite() {
    let bk = "";
    try {
      let p = ctx.U.personFromGUID(chosen);
      let name = props.condensed ? p.shortName : p.name;
      if (chosen && name == props.location.state.hilitedPerson) bk = "w3-ios-yellow";
    } catch (err) { }
    return bk;
  }
  //======================================================
  // function staffOptions(activity,doy,dow=false) {
  //   let options = [];
  //   try {
  //     ctx.schedule.staff.forEach(person => { if (available(person)) options.push(person)}); 
  //   } catch (err) { }
  //   return options;

  //   function available(person) {
  //     if (!person.canDo[activity.guid]) return false;
  //     let doys = person.vacations.map(stamp => ctx.T.doy(stamp)-1);   // adjust for Jan 1 being 1 not zero
  //     if (doys.indexOf(doy) >= 0) return false;

  //     try { if (dow && !person[weekDays[dow]]) return false; } catch(err) {}
  //     return true;
  //   }
  // }
  //======================================================

  return <Stack direction="row">
    <FormControl size="small" sx={{ width: "90%", border: chosen ? "" : "2px solid #f44336" }} ><NativeSelect value={chosen} onChange={handleChange} className={className||""}>
      <option value=""></option>
      {ctx.U.staffOptions(props.activity,props.doy,props.dow).map((item, n) => <option value={item.guid} key={item.guid} >{props.condensed ? item.shortName: item.name}</option>)}
    </NativeSelect></FormControl>
    <IconButton sx={{ width: 20 }} onClick={(ev) => dailyNotes(props.doy, ev)} className={getHilite()}> 
      {ctx.schedule.notes[props.activity.guid][props.doy].locked ? <LockIcon sx={{ height: 12 }} /> : <MoreHorizIcon sx={{ height: 12 }} />}
    </IconButton>
  </Stack>
}
//********************************************************************************************************
function Month(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  ctx.schedule ||= {};
  if (typeof ctx.schedule.assignments !== "object") ctx.schedule.assignments = {};
  if (typeof ctx.schedule.info !== "object") ctx.schedule.info = {};
  if (!Array.isArray(ctx.schedule.staff)) ctx.schedule.staff = [];
  if (!Array.isArray(ctx.schedule.activities)) ctx.schedule.activities = [];
  if (typeof ctx.schedule.notes !== "object") ctx.schedule.notes = {};

  let year = (new Date).getFullYear();
  try { year = Number(ctx.schedule.info.startDate.substring(0, 4)) } catch (err) { }

  let [state, setState] = useState({ grid: "monthly", condensed: false, edit: false});
  const [showStats, setShowStats] = useState(false);
  let location = useLocation();
  try { props = { ...props, ...location.state } } catch (err) { }
  
  //======================================================
  function stats() {
    if (showStats === false) setShowStats(ctx.U.getStats()); else setShowStats(false);
  }
  //======================================================
  function handleChange(event) {
    event.preventDefault();
    try {
      if (event.target.type === "checkbox") {
        state[event.target.name] = !!! state[event.target.name];
      } else state[event.target.name] = event.target.value;
    } catch (err) { }
    setState({...state});
  }
  //======================================================
  async function eraseAssignments() {
    await ctx.U.eraseAssignments();
    setState({ ...state, edit: false });
  }
  //======================================================
  async function replicateWeek() {
    await ctx.U.replicateWeek();
    setState({ ...state, edit: false });
  }
  //======================================================
  function edit(editState) {
    setState({ ...state, edit: editState, loading: true });
  }
  //======================================================
  function flattenTooltip(actguid,doy,newline="\n") {
    let out = "", noconflict = false, lines = 0;
    try {
      out += flattenObj(ctx.reasons[actguid][doy])
    } catch (err) { out = "" }
    return noconflict? out + "No conflict" : out;

    function flattenObj(obj) {
      let out = "";
      lines++;
      for (let kk in obj) {
        if (typeof obj[kk] === "object") {
          out += kk + ": " + newline + flattenObj(obj[kk]);
        } else {
          out += kk + ": " +   obj[kk];
        }
        out += newline;
        // out += kk + ": " + (typeof obj[kk] === "object" ? newline + flattenObj(obj[kk]) : obj[kk]) + newline;
      }
      if (lines <= 2 && out.length == 0) noconflict = true;
      return out;
    }
  }
  //======================================================
  async function makeSchedule() {
    await ctx.U.makeSchedule();
    setState({ ...state, edit: false });
  }
  //======================================================
  async function copyFromPublished(event) {

    // let [published, _] = await ctx.T.post("getPublished", { slug: ctx.schedule.info.slug, accountNo: ctx.schedule.info.accountNo });
    // let done = 0;
    // for (let person of ctx.schedule.staff) {
    //   let person2 = published.staff.find(p => p.name == person.name);
    //   if (!person2) continue;
    //   person.role = person2.role;
    //   person.providerType = person2.providerType;
    //   person.providerNo = person2.providerNo;
    //   done++;
    // }
    // for (let act of ctx.schedule.activities) {
    //   let act2 = published.activities.find(a => a.name == act.name);
    //   if (!act2) continue;
    //   act.startTime = act2.startTime;
    //   act.endTime = act2.endTime;
    //   done++;
    // }
  }
  //======================================================
  function getClassName(p,activity,doy) {
    let className = "";
    try { className = ctx.schedule.notes[activity.guid][doy].colorClass } catch (err) { }

    let fits = false;
    try {
      fits = ctx.U.fits(activity, p, doy)[0];
    } catch (err) { }
    if (p === false) className = "w3-text-red"; else if (!fits) className = "w3-2021-buttercream";
    return className;
  }
  //======================================================
  function createContent(editable) {
    let objContent = {};

    let day1 = ctx.T.doy(ctx.schedule.info.startDate);
    let dayN = ctx.T.doy(ctx.schedule.info.endDate);
    let names = new Set();
    for (let activity of ctx.schedule.activities) {
      for (let doy = day1; doy <= dayN; doy++) try {
        objContent[activity.name + "-" + doy] = "";
        let needsFilling = ctx.U.needsFilling(activity, year, doy); 
        if (!needsFilling) continue;
        let p = false; try { p = ctx.U.personFromGUID(ctx.schedule.assignments[activity.guid][doy]) } catch(err) {};
        // let className = getClassName(p,activity,doy);
        let tooltip = flattenTooltip(activity.guid, doy, "<br>");

        if (p) names.add(state.condensed ? p.shortName : p.name); // for hilite purposes
        if (editable) {
          try {
            objContent[activity.name + "-" + doy] = <Select activity={activity} doy={doy} dow={ctx.T.dowFromDoy(doy, year)} location={location}
              condensed={state.condensed} className={()=>getClassName(p,activity,doy)} tooltip={tooltip} year={year}
            />
          } catch(err){}
        } else {
          let lineElem = <Box className={getClassName(p,activity,doy)} title={"Click to see details"} sx={tooltip ? { cursor: "pointer" } : {}}
            onClick={() => { if (tooltip) ctx.T.alert(tooltip, `Conflicts for ${ activity.name } on ${ ctx.T.dateFromDoy(doy, year)[1] }`) }}>
            {p === false ? <><b>OPEN</b></> : state.condensed ? p.shortName : p.name}
          </Box>;
          objContent[activity.name + "-" + doy] = lineElem;
        }
      } catch (err) { objContent[activity.name + "-" + doy] = <Box className="w3-red" >&nbsp;</Box>}
    }
    // Add vacations
    ctx.schedule.vacations = ctx.U.calcVacations();
    for (let doy = 1; doy <= 366; doy++) try {
      objContent["Vacations-" + doy] = <ctx.U.VacationPopup away={ctx.schedule.vacations[doy] ??= [] } doy={doy} />
    } catch (err) { }

    // Add names for hilite purposes
    let arNames = [];
    names.forEach((n => arNames.push(n)));
    arNames.sort();
    arNames.unshift("None");
    objContent["names"] = arNames;

    return objContent;
  }
  //======================================================
  function getRowHeaders() {
    let ar = ctx.schedule.activities.map(act => act.name);
    ar.push("Vacations");
    return ar;
  }
  //======================================================
  useEffect(() => {
    setState({ ...state, loading: false });
  },[state.edit]);
  //======================================================

   return (<>
    {showStats && <div>
      <Stack direction="row" justifyContent="space-between" sx={{mt:1}}>
        <Box component="h2" sx={{ ml: 1 }}>Stats</Box>
        <Link href="#" sx={{ mt:1, mr:2 }} onClick={stats}>Close</Link>
      </Stack>
      <div dangerouslySetInnerHTML={{__html:showStats}}/>
    </div>}
    {!showStats && <> <Container maxWidth="lg">
      <Stack direction="row" justifyContent="space-between">
        <Box component="h2" sx={{ flexGrow: 1 }}>Assignments</Box>
        <Link href="#" sx={{ mt: 3 }} onClick={stats}>Stats</Link>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Box>Display:
          <ToggleButtonGroup sx={{ mx: 2 }}>
            <ToggleButton sx={{ color: "primary.main" }} size="small" value={true} selected={state.edit}
              onClick={() => edit(true)}>Editable</ToggleButton>
            <ToggleButton sx={{ color: "primary.main" }} size="small" value={false} selected={!state.edit}
              onClick={() => edit(false)}>View Only</ToggleButton>
          </ToggleButtonGroup>
          &nbsp;&nbsp;
          <ToggleButtonGroup>
            <ToggleButton sx={{ color: "primary.main" }} size="small" value={false} selected={state.condensed == false}
              onClick={() => { setState({ ...state, condensed: false }) }}>Last Names</ToggleButton>
            <ToggleButton sx={{ color: "primary.main" }} size="small" value={true} selected={state.condensed == true}
              onClick={() => { setState({ ...state, condensed: true }) }}>Initials</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {ctx.T.myRole.edit && <Stack direction="row" justifyContent="flex-end" >
          <Button variant="outlined" onClick={eraseAssignments} sx={{ ml: 5 }}>Erase Assignments</Button>
          <Button variant="outlined" onClick={replicateWeek} sx={{ ml: 2 }}>Replicate Week</Button>
          <Button variant="outlined" onClick={makeSchedule} sx={{ ml: 2 }}>Make Schedule</Button>
        </Stack>}
      </Stack>
    </Container>
      {state.loading && <Box component="h2" sx={{ textAlign: "center", mt: 10 }}>{loadingMessage}</Box>}
      {!state.loading && <MonthGrid
        rowHeaders={getRowHeaders()}
        condensed={state.condensed}
        edit={state.edit}
        holidays={ctx.U.calcHolidays()}
        content={createContent(state.edit)}
        year={year}
      />}
    </>}

    <style> {`
      #statsTable td {
        padding-left:25px;
      }
    `}</style>
  </>)
}
Month.defaultProps = {
  bkColor: "#008ae6",
  company: " AMC Logic, LTD"
}

export default Month;