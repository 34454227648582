import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppBar, Toolbar, Box, Button, Stack, Typography} from '@mui/material';
import { useContext } from 'react';
import { GlobalContext } from '../App';


function Topbar(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  let navigate = useNavigate();

  let subheading = "";
  try {
    subheading = (ctx.schedule?.backupComment || "");
    if(subheading.length > 0) subheading += ` from ${ ctx.T.dateTime(ctx.schedule.stamp) }`
    if (props?.centerSubHeading) subheading = props.centerSubHeading;
  } catch(err) {}
  
  return (
    <AppBar sx={{backgroundColor:props.color, justifyContent: "space-between", padding: "5px", height: props.height || 90}} className={props.classes} position="sticky">
      <Toolbar>
        <Stack direction="row" sx={{ width:0.3, textAlign: "left" }}>
          <img src={props.image} alt="" style={{ width: props.imageWidth ??"120px", marginLeft: props.marginLeft ?? "-40px" }} />
          <Box component="h2" alignSelf="center" >{props.title}</Box>
        </Stack>
        <Stack sx={{width:0.4, alignItems:"center"}} >
          <><Typography variant="h5" >&nbsp;</Typography>
          <Typography variant="h5" >{props?.centerHeading || ctx.schedule?.info?.name || ""}</Typography>
          <Typography variant="subtitle1" className="w3-yellow">{subheading}</Typography></>
        </Stack>
        <Box sx={{ width: 0.3, textAlign: "right" }}>{props.user ? <Box>Welcome {props.user}</Box> : <Button className="w3-white" onClick={() => navigate("/login")} size="small">Login</Button>}</Box>
      </Toolbar>
    </AppBar>
  );
  
}

Topbar.defaultProps = {
  classes: "",
  color: "primary.main",
  title: "Title",
}

export default Topbar;
