import {
  Box, Grid, Stack, Button, Typography, Tooltip, FormGroup, FormControlLabel, Checkbox, ButtonGroup, Radio,
  Container, List, Link, ListItem, ListItemText, ListItemButton, Divider, TextField, FormControl, RadioGroup, FormLabel
} from '@mui/material';
import WeekList from './WeekList.js';
import { useState, useContext, useEffect, useRef } from 'react';
import { GlobalContext } from '../App';
import SortableList from './SortableList';
import DailyDetails from './DailyDetails.js';

//********************************************************************************************************
let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
let sortOrder = 1;
let timeOptions = [
  { a: "07:00", b: "17:00" },
  { a: "17:00", b: "22:00" },
  { a: "17:00", b: "07:00" },
  { a: "07:00", b: "07:00" },
  { a: "", b: "" }
];
let tOptionNow= 0;
let dailyDetails = {};

//********************************************************************************************************
function Activity(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  ctx.schedule.activities ||= [];
  let [activities, setActivities] = useState(ctx.schedule.activities);
  let [selectedIndex, setSelectedIndex] = useState(ctx.schedule.activities.length > 0 ? 0 : -1);


  const panelDetails = {
    timeLabel: "", weeks:Array(52).fill(true) , startTime:"", endTime:"", 
    Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: true, Sunday: true, holidays: false,
    stdRate: "", specialRate: "", premiumRate: "", hours: "",
    notes:"",daysApart:0, equalWeekdays:1,equalWeekends:1,equalHolidays:1,equalFridays:0,equalSatSun:0,consecutiveDays:"oneDay"
  }


  //======================================================
  function handleChange(event) {
    if (selectedIndex < 0) return;
    event.preventDefault();
    try {
      let obj = activities[selectedIndex];
      if (event.target.type === "checkbox") {
        obj[event.target.name] = !!! obj[event.target.name];
      } else obj[event.target.name] = event.target.value;
      ctx.schedule.activities = [...activities];
      setActivities(ctx.schedule.activities);
      // console.log(obj);
    } catch (err) { }
  }
  //======================================================
  function receiveDetails(details) {
    dailyDetails = details;
  }
  //======================================================
  function handleSameDay(event) {
    event.preventDefault();
    if (event.target.type !== "checkbox") return;
    let obj = activities[selectedIndex];
    obj.sameDay ||= {}
    try {
      obj.sameDay[event.target.name] = !!! obj.sameDay[event.target.name];
      ctx.schedule.activities = [...activities];
      setActivities(ctx.schedule.activities);
    } catch (err) { }
  }
  //======================================================
  function handleNextDay(event) {
    event.preventDefault();
    if (event.target.type !== "checkbox") return;
    let obj = activities[selectedIndex];
    obj.nextDay ||= {}
    try {
      obj.nextDay[event.target.name] = !!! obj.nextDay[event.target.name];
      ctx.schedule.activities = [...activities];
      setActivities(ctx.schedule.activities);
    } catch (err) { }
  }
  //======================================================
  function cycleTime() {
    activities[selectedIndex].startTime = timeOptions[tOptionNow].a;
    activities[selectedIndex].endTime = timeOptions[tOptionNow].b;
    ctx.schedule.activities = [...activities];
    setActivities(ctx.schedule.activities);
    tOptionNow += 1;
    if (tOptionNow >= timeOptions.length) tOptionNow = 0;
  }
  //======================================================
  function newActivity(name) {
    return {...panelDetails, name:name, guid: ctx.T.guid()};
  }
  //======================================================
  // async function newActivity() {
  //   let answer = await ctx.T.prompt("Please enter a name for the new activity","Activity");
  //   if (ctx.T.isEmpty(answer)) return;
  //   activities.splice(selectedIndex+1,0, { name: answer, startTime:"", endTime:"", guid: ctx.T.guid(), ...panelDetails});
  //   showDetail(selectedIndex+1);
  // }
  //======================================================
  async function chooseWeeks() {
    if (selectedIndex < 0) return;
    const component = <WeekList weeks={activities[selectedIndex].weeks} />;
    ctx.T.dlg({ message: component, width:"lg", textOk:"Close",textCancel:false,actionsAtTop:true});
  }
  //======================================================
  function showDetail(obj, index, ar) {
    tOptionNow = 0;
    try {
      for (const [key, value] of Object.entries(panelDetails)) { if (typeof obj[key] === "undefined") obj[key] = value; };  // adding any missing keys from panelDetails
      ctx.schedule.activities = ar;
      ctx.schedule.activities[index] = obj;
      setSelectedIndex(index);
      setActivities([...ctx.schedule.activities]);
    } catch (err) { }
  }
  //======================================================
  function activityName() {
    try {
      return activities[selectedIndex].name;
    } catch (err) { }
    return "";
  }
  //======================================================
  function isChecked(checkName,sameDay=false,nextDay=false) {
    try {
      if (typeof activities[selectedIndex][checkName] == "undefined") {   // undefined will return true;
        activities[selectedIndex][checkName] = true;
        return true;
      } 
      return !! activities[selectedIndex][checkName];
    } catch (err) {}                      
    return false;                 
  }
  //======================================================
  function isCheckedNextDay(checkName) {
    activities[selectedIndex].nextDay ||= {}
    try {
      if (typeof activities[selectedIndex]["nextDay"][checkName] == "undefined") {   // undefined will return true;
        activities[selectedIndex]["nextDay"][checkName] = true;
        return true;
      } 
      return !! activities[selectedIndex]["nextDay"][checkName];
    } catch (err) {console.log(err.message)}                      
    return false;                 
  }
  //======================================================
  function isCheckedSameDay(checkName) {
    activities[selectedIndex].sameDay ||= {}
    try {
      if (typeof activities[selectedIndex]["sameDay"][checkName] == "undefined") {   // undefined will return true;
        activities[selectedIndex]["sameDay"][checkName] = true;
        return true;
      } 
      return !! activities[selectedIndex]["sameDay"][checkName];
    } catch (err) {console.log(err.message)}                      
    return false;                 
  }
  //======================================================
  function newRow(label, dataSlug, minRows=0, suffix=false) {
    return (<>
      <Grid item xs={3}>{label}</Grid>
      <Grid item xs={suffix?6:9}><TextField variant="outlined" size="small" multiline={minRows > 0 ? true : false} minRows={minRows}
        name={dataSlug} value={activities[selectedIndex][dataSlug]}
        sx={{ width: "90%" }} onChange={handleChange} />
      </Grid>
      {suffix && <Grid item xs={3}>{suffix}</Grid>}
    </>)
  }
  //======================================================
  useEffect(() => {
    if (selectedIndex < 0) return;
    showDetail(selectedIndex);
    ctx.schedule.activities = activities;
  },[activities.length]);
  //======================================================

  try { if (!activities[selectedIndex].consecutiveDays) activities[selectedIndex].consecutiveDays = "oneDay"; } catch(err) {}

  return (<>
    <Box sx={{display:"flex", flexDirection:"row", px:2}}>
      <Box component="h2" sx={{ flexGrow:1}}>Duties to Schedule</Box>
    </Box>
    <Stack direction="row" alignItems="flex-start" sx={{p:2}}>
      <Container sx={{ width: "300px" }}>
        <SortableList items={activities} nameKey="name" showButtons newItem={newActivity} classSel="w3-flat-clouds" classUnsel="w3-white" initialSelection={0} onSelected={showDetail}
          sx={{ height: 600, width: 170, border: "1px solid", p: 0 }} />
      </Container>
      
      {selectedIndex < 0 && <Grid justifyContent="center" className="w3-sand" container sx={{p:4 }}>
        <h3>No Duties on file.  Click
          <Box component="i" className="fa fa-plus-circle" sx={{ mx: 1, fontSize: "1.5rem", color: "#00A170"}} /> on the left to add
        </h3>
      </Grid>}
      {selectedIndex >= 0 && <Stack>
        <Stack direction="row" spacing={3} alignItems="flex-start" divider={<Divider orientation="vertical" flexItem />} sx={{ border: 1, p: 2 }}>
          <Grid container>
            <Grid item xs={10}><Typography variant="h5">Basic Details</Typography></Grid>
            <Grid item xs={1}><Link href="#" onClick={async () => {
              let ok = await ctx.T.dlg({
                title: `Modify Details for '${ activities[selectedIndex].name }' by Week-day `,
                message: <DailyDetails ctx={ctx} activity={activities[selectedIndex]} receiveDetails={receiveDetails} />,
                width: "sm",
              });
              if (ok) activities[selectedIndex].dailyDetails = dailyDetails;
            }}>Advanced</Link></Grid>
            <Grid item xs={1}>&nbsp;</Grid>

            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={3}>Duty</Grid>
            <Grid item xs={9}>{activityName()}</Grid>

            <Grid item xs={3}>Duty Hours</Grid>
            <Grid item xs={9}>
              <TextField variant="outlined" size="small" type="time"  name="startTime" value={activities[selectedIndex]["startTime"]}
                onChange={handleChange} />
              <span style={{ margin: "12px", fontSize:"1.5rem"}}>to</span>
              <TextField variant="outlined" size="small" type="time" name="endTime" value={activities[selectedIndex]["endTime"]}
                onChange={handleChange} />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <Button sx={{ px:1, mb:2,fontSize: "0.9rem"}} onClick={cycleTime}>Commonly Used Shifts</Button>
            </Grid>

            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={3}>Days to Fill</Grid>
            <Grid item xs={9}>
              <FormGroup row>
                { weekDays.map((day, index) => <FormControlLabel control={<Checkbox value={true} checked={isChecked(day)} name={day} onClick={handleChange} />} key={day} label={day} />)}
                <FormControlLabel control={<Checkbox value={true} checked={isChecked("holidays")} name="holidays" onClick={handleChange} />} key="holidays" label="Holidays" />
              </FormGroup>
            </Grid>
            {/* <Grid item xs={3}>Weeks to Fill</Grid>
            <Grid item xs={9}>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Button variant="outlined" sx={{ m: 1 }} onClick={chooseWeeks}>Select Weeks to Fill</Button> &nbsp;  (Default: ALL)
              </Box>
            </Grid> */}
        
            <Grid item xs={12}>&nbsp;</Grid>
            {newRow("Notes", "notes", 3)}
            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={3}>Payment Rate</Grid>
            <Grid item xs={9}>
              <Stack direction="row" alignItems="center">
                <TextField variant="outlined" size="small" name="stdRate" value={activities[selectedIndex]["stdRate"]} label="Standard" onChange={handleChange} />
                <TextField variant="outlined" size="small" name="specialRate" value={activities[selectedIndex]["specialRate"]} label="Special" onChange={handleChange} />
                <TextField variant="outlined" size="small" name="premiumRate" value={activities[selectedIndex]["premiumRate"]} label="Premium" onChange={handleChange} />
                <Box sx={{ ml:2 }}>$/hr</Box>
              </Stack>
            </Grid>
            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={3}>Shift Duration</Grid>
            <Grid item xs={9}>
              <Stack direction="row" alignItems="center">
                <TextField variant="outlined" size="small" name="hours" value={activities[selectedIndex]["hours"]} onChange={handleChange} />
                <Box sx={{ ml:2 }}>hours</Box>
              </Stack>
            </Grid>

            <Grid item xs={12}>&nbsp;</Grid>
          </Grid>
          <Grid>
            <Typography variant="h5">Dependencies (Optional)</Typography>
            <Stack direction="row">
              <FormGroup sx={{mr:2}}>
                <p><u>Same Day</u><br/>A person doing <b>"{activityName()}"</b> can also do on the same day:</p>
                {ctx.schedule.activities.map((activity, index) => <FormControlLabel control={<Checkbox value={true} color="success" disabled={activity.name == activityName()}
                  checked={isCheckedSameDay(activity.guid)}
                  name={activity.guid} onClick={handleSameDay} />} key={activity.guid} label={activity.name} />)}
              </FormGroup>
              <FormGroup >
              <p><u>Next Day</u><br/>A person doing <b>"{activityName()}"</b> today, can do on the next day:</p>
              {ctx.schedule.activities.map((activity, index) => <FormControlLabel control={<Checkbox value={true} color="success"
                checked={isCheckedNextDay(activity.guid)}
                name={activity.guid} onClick={handleNextDay} />} key={activity.guid} label={activity.name} />)}
              </FormGroup>
            </Stack>
          </Grid>
        </Stack>
        <Stack spacing={3}  sx={{ mt:2, border: 1, p: 2 }}>
          <Typography variant="h5">Scheduling Rules (Optional)</Typography>
          <FormGroup>
            <FormControl>
              <FormLabel sx={{mt:3,p:0.5}} className="w3-flat-clouds">Scheduling Same Person for Consecutive Days</FormLabel>
              <RadioGroup name="consecutiveDays" onChange={handleChange} value={activities[selectedIndex].consecutiveDays}>
                <Stack direction="row" alignItems="center">
                  <FormControlLabel value="oneDay" control={<Radio />} label="Do one day at a time." />
                  Same person may be scheduled for this duty every &nbsp;
                  <TextField variant="outlined" sx={{ width: 70 }} size="small" name="daysApart" value={activities[selectedIndex]["daysApart"]} onChange={handleChange} />
                  &nbsp; days
                </Stack>
                <FormControlLabel value="friSatSun" control={<Radio />} label="As above  on weekdays, but schedule the same person for Fri, Sat, Sun" />
                <FormControlLabel value="satSun" control={<Radio />} label="As above on weekdays, but schedule the same person for Sat & Sun" />
                <FormControlLabel value="fiveDays" control={<Radio />} label="Do 5-day week at a time" />
                <FormControlLabel value="sevenDays" control={<Radio />} label="Do 7-day week at a time" />
              </RadioGroup>
            </FormControl>
            <FormLabel sx={{mt:3,p:0.5}} className="w3-flat-clouds">Equalizing Workload</FormLabel>
            <FormControlLabel control={<Checkbox value={true} checked={isChecked("equalWeekdays")} name="equalWeekdays" onClick={handleChange} key="EqualWeekdays" /> } label="Equalize weekdays" />
            <FormControlLabel control={<Checkbox value={true} checked={isChecked("equalWeekends")} name="equalWeekends" onClick={handleChange} key="equalWeekends" /> } label="Equalize weekends" />
            <FormControlLabel control={<Checkbox value={true} checked={isChecked("equalSatSun")} name="equalSatSun" onClick={handleChange} key="equalSatSun" /> } label="Equalize Saturday & Sunday separately" />
            <FormControlLabel control={<Checkbox value={true} checked={isChecked("equalFridays")} name="equalFridays" onClick={handleChange} key="equalFridays" /> } label="Equalize Fridays" />
            <FormControlLabel control={<Checkbox value={true} checked={isChecked("equalHolidays")} name="equalHolidays" onClick={handleChange} key="equalHolidays" /> } label="Equalize Holidays" />
          </FormGroup>
        </Stack>
      </Stack>}
    </Stack>
      <p />
  </>)
}

Activity.defaultProps = {
  bkColor: "#008ae6",
  company: " AMC Logic, LTD"
}

export default Activity;
