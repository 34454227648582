import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Box, Stack, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DatePicker, {Calendar, DateObject} from "react-multi-date-picker";
import CircularProgress from '@mui/material/CircularProgress';

const _T = {user:{}};
//********************************************************************************************************
_T.loading = function (message=false)  // Call the returned value (root) as root.unmount() to remove the loading dlg
{
  const root = ReactDOM.createRoot(document.getElementById("popups"));
  const dlg = <Dialog open={true}  keyboard="false" maxWidth="xs" fullWidth={true}>
    <DialogContent>
      <Box pt={5} ><center><CircularProgress color="success" size={80} /></center></Box>
      {message && <Box pt={5} component="h3" sx={{textAlign:"center"}}>{message}</Box>}
    </DialogContent>
  </Dialog>
  root.render(dlg);

  return root;
}
//********************************************************************************************************
_T.actionSheet = async function (title, ar, pageSize=false, topComponent=false, bottomComponent=false)  // ar has objects as {text:text,action:text}
{
  let from = 1, to = 10; if (to > ar.length) to = ar.length;
  let topBar = <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
    <Button variant="outlined" size="small" onClick={prev}>{"<<"}</Button>
    Showing {from} to {to} of {ar.length} Options
    <Button variant="outlined" size="small" onClick={next}>{">>"}</Button>
  </Stack> 

  return new Promise(resolve => {
    const root = ReactDOM.createRoot(document.getElementById("popups"));
    function handleClick (event) {
      event.stopPropagation();
      root.unmount(document.getElementById("popups"));
      resolve(event.target.value);
    }
    const dlg = <Dialog open={true}  keyboard="false" scroll="paper" maxWidth="xs" fullWidth={true}>
      <DialogTitle className={"w3-2021-desert-mist"} >
        {title}
      </DialogTitle>
      <div style={{ margin: "0.5rem" }}>
        <DialogContent>
          {topComponent}
          {pageSize && topBar}
          <Stack>
          {ar.map((obj, nn) => <Button variant="outlined" className={obj.class || "" }  color={obj.color || "secondary"} sx={{ width: "100%", m: "auto", mb: 1 }} 
            onClick={handleClick} value={obj.action} key={nn}>{obj.text}</Button>)}
          </Stack>
          {bottomComponent}
        </DialogContent>
      </div>
    </Dialog>
    root.render(dlg);
  });

  function prev() {
    from -= pageSize;
    if (from < 1) from = 1;
    to = from + pageSize - 1;
    if (to > ar.length) to = ar.length;
    // alert(`${ from } - ${ to }`);
  }
  function next() {
    if (to >= ar.length) return;
    from += pageSize;
    if (from >= ar.length) from = ar.length - pageSize;
    to = from + pageSize - 1;
    if (to > ar.length) to = ar.length;
    // alert(`${ from } - ${ to }`);
  }
}
//********************************************************************************************************
_T.prompt = async function (prompt, title = "",text="",rows=1) {

  let fragment = <>
    <Box sx={{mb:1}}>{prompt}</Box>
    <TextField variant="outlined" rows={rows} multiline={rows>1} autoFocus fullWidth color="info" id="toolsPromptInputID" defaultValue={text}/>
  </>
  return await _T.confirm(fragment, title, "Ok", "Cancel");
}
//********************************************************************************************************
_T.alert = async function (message, title = "Alert") {
  return await _T.confirm(message, title, "Ok", false);
}
//********************************************************************************************************
_T.confirm = async function (message, title = "Please Confirm", textOk = "Yes", textCancel = "No",options={}) {
  return await _T.dlg(Object.assign({
    show: true,
    title: title,
    message: message,
    textOk: textOk,
    textCancel: textCancel,
  },options));
}
//********************************************************************************************************
_T.dlg = async function (options = {}) {
  const props = Object.assign({
    show: true,
    width: "sm",
    fullScreen: false,
    actionsAtTop: false,
    title: "",
    message: "",
    textOk: "Ok",
    textCancel: "Cancel",
    actionSheet: false,
    components: false,
    handleOk: ()=>true,
    extraButtons:[],
  }, options);

  return new Promise(resolve => {
    const root = ReactDOM.createRoot(document.getElementById("popups"));
    props.callback = function (result) {
      root.unmount(document.getElementById("popups"));
      resolve(result);
    }
    const dlg = <DialogBox {...props} />
    root.render(dlg);
  });
}
//********************************************************************************************************
_T.moveArrayItem = function (ar,oldIndex,newIndex) {
  let [movedItem] = ar.splice(oldIndex, 1);
  ar.splice(newIndex, 0, movedItem);
  return ar;
}
//********************************************************************************************************
_T.isEmail = function (str) {
  var filter = /^([a-zA-Z0-9_'\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,10})+$/;
  return filter.test(str);
}
//********************************************************************************************************
_T.isEmpty = function (obj) {
  if (Array.isArray(obj) && obj.length > 0) return false;
  if (typeof obj == "object") {
    if (Object.keys(obj).length === 0) return true; else return false; // because {} is true in next step
  }
  return !(!!obj);              // takes care of boolean,"",{}(true),NaN,0,undefined,null
}
_T.isBlank = _T.isEmpty;
//********************************************************************************************************
_T.wait = async function (seconds) {
  return new Promise(resolve => {
    setTimeout(function () { resolve() }, seconds*1000);
  });
}
//***************************************************************************************************************
_T.shortDate = function (dt = Date.now(),format="MM/DD/YYYY") // dt is a Date object or a timestamp in ms
{
  try {
    if (dt instanceof Date); else dt = new Date(dt);
    let dateObject = new DateObject(dt);
    return dateObject.format(format);
    // if(format == "MDY") return `${ 1 + dt.getMonth() }/${ dt.getDate() }/${ dt.getFullYear() }`;
    // if(format == "YMD") return `${ dt.getFullYear() }-${ 1 + dt.getMonth() }-${ dt.getDate() } }`;
  } catch (err) { }
  return "";
}
//***************************************************************************************************************
_T.dateTime = function (dt = Date.now()) // dt is a Date object or a timestamp in ms
{
  if (dt === 0) return "";
  try {
    if (dt instanceof Date); else dt = new Date(dt);
    return `${ 1 + dt.getMonth() }/${ dt.getDate() }/${ dt.getFullYear() } ${dt.toLocaleTimeString().substring(0,8)}`;
  } catch (err) { }
  return "";
}
//***************************************************************************************************************
_T.guid = function () {
  return "G" + Math.floor(Math.random() * 1000) + Date.now();
}
//***************************************************************************************************************
_T.date = function (strDate) {									// date is YYYY-MM-DD without time.  Use this to avoid adjustment due to time zone
  try {
    if (typeof strDate == "string") return new Date(strDate + "T00:00:00");
  } catch (err) { }
  return false;
}
//***************************************************************************************************************
_T.dateObj = function (strDate, formatIn ="YYYY-MM-DD" ) {	// date is YYYY-MM-DD without time.  Use this to avoid adjustment due to time zone
  try {
    let dObj = new DateObject();
    return dObj.setFormat(formatIn).parse(strDate);
  } catch (err) { }
  return false;
}
//***************************************************************************************************************
_T.formatDate = function (strDate, formatOut, formatIn ="YYYY-MM-DD" ) {									// date is YYYY-MM-DD without time.  Use this to avoid adjustment due to time zone
  try {
    let dObj = new DateObject();
    return dObj.setFormat(formatIn).parse(strDate).format(formatOut);
  } catch (err) { }
  return false;
}
//***************************************************************************************************************
_T.doyNow = function () {
  let dt = _T.shortDate(new Date(), "YYYY-MM-DD");
  return _T.doy(dt);
}
//***************************************************************************************************************
_T.doy = function (date) {									// date is YYYY-MM-DD without time; Jan 1 is day 1 not zero
  try {
    if (typeof date == "string") date = new Date(date + "T00:00:00"); else date = new Date(date);
    if (date === "Invalid Date") return false;
    var onejan = new Date(date.getFullYear(), 0, 1);
    if (onejan === "Invalid Date") return false;
    let days = Math.ceil((date - onejan) / 86400000) + 1;
    if (isNaN(days)) return false; else return days;
  } catch (err) { }
  return false;
}
//***************************************************************************************************************
_T.dateFromDoy = function (doy,year) {									// Jan 1 is day 1 not zero
  let onejan = (new Date(`${ year }-01-01T00:00:00`)).getTime();
  let ms = 86400000 * (Number(doy) - 1) + onejan;
  let date = new Date(ms);
  return [date, _T.shortDate(date)];
}
//***************************************************************************************************************
_T.dowFromDoy = function (doy,year) {									// Jan 1 is day 1 not zero
  let dt = new Date(year, 0, doy);
  return dt.getDay();
}
//***************************************************************************************************************
_T.rgb2hex = function (rgb) {
  rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return (rgb && rgb.length === 4) ? "#" +
    ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
    ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
    ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
}
//***************************************************************************************************************
_T.normalizePhone = function (phone = "") {
  phone = phone.replace(/[^0-9]/g, "");
  if (phone.length < 10) return false;
  return phone;
}
//***************************************************************************************************************
_T.randomItem = function (ar, startAt = 0) {
  if (!Array.isArray(ar)) return "";
  if (startAt >= ar.length) return "";
  let index = Math.floor(Math.random() * (ar.length - startAt)) + startAt;
  return ar[index];
}
//***************************************************************************************************************
_T.randomPassword = function () {
  var out = "";
  const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
  for (let p = 0; p < 10; p++) {
    let index = Math.floor(Math.random() * chars.length);
    out += chars[index];
  }
  return out;
}
//***************************************************************************************************************
_T.readForm = function (formID)    
{
  var data = {};
  var form = document.querySelector("#"+formID);
  try {
    var formData = new FormData(form);
    for (var entry of formData.entries()) data[entry[0]] = entry[1];
  } catch (err) { };

  // Add unchecked checkboxes and radios
  var elems = form.querySelectorAll("input");
  elems.forEach(function (elem) {
    try {
      switch (elem.type) {
        case "radio":
          if (data[elem.name] && data[elem.name] !== "") break;     // else fall through
        case "checkbox": if (elem.checked) data[elem.name] = elem.value; else data[elem.name] = "";
          break;
      }
    } catch (err) { }
  });
  return data;
}
//***************************************************************************************************************
_T.readInputs = function (sel) {
  var data = {};
  var elems = document.querySelectorAll(sel + " input, " + sel + " textarea");
  elems.forEach(function (elem) {
    try {
      switch (elem.type) {
        case "radio":
          if (data[elem.name] && data[elem.name] !== "") break;     // else fall through
        case "checkbox": if (elem.checked) data[elem.name] = elem.value; else data[elem.name] = "";
          break;
        default: data[elem.name] = elem.value; break;
      }
    } catch(err){}
  });
  return data;
}
//***************************************************************************************************************
_T.get = async function (action, data = {}, headers = {})           //usage: [obj,txt] = await _T.get(..)
{
  let qstr = "";
  if (typeof data == "object") {
    for (let key in data) qstr += `${ qstr.length == 0 ? "?" : "&" }${ key }=${ data[key] }`;
  } else qstr += data;
  let $url = encodeURI(`https://${ _T.server }/${ action }${qstr}`);

  try {
    var response = await fetch($url, {
      method: "GET",
    });
    let text = await response.text().catch(err=>{});
    try { var out = JSON.parse(text) } catch (err) { }
    if (typeof out == "object") return [out, false]; else return [{}, text];
  } catch (err) { }
  return [{}, ""];
}
//********************************************************************************************************
_T.formPost = async function (url, data = {})           //usage: [obj,txt] = await _T.post(..)
{
  let formData = new FormData();
  try {
    for (let key in data) formData.append(key, data[key]);
    var response = await fetch(url, {
      method: "POST",
      body: formData,
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache",
      redirect: "follow",
    });
    let text = await response.text();
    try { var out = JSON.parse(text) } catch (err) { }
    if (typeof out == "object") return [out, false]; else return [{}, text];
  } catch (err) { console.log("Webpost ERR",err.message)}
  return [{}, ""];
}
//********************************************************************************************************
_T.post = async function (action, inData = {}, headers = {})           //usage: [obj,txt] = await _T.post(..)
{
  headers['content-type'] = 'application/json';
  try {
    var data = { ...inData, user: _T.user, stamp: Date.now() };
  } catch (err) { }

  try {
    var response = await fetch(`https://${ _T.server }/data/${ action }`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
      // mode: "same-origin",
      cache: "no-cache"
    });
    let text = await response.text();
    try { var out = JSON.parse(text) } catch (err) { }
    if (typeof out == "object") {
      try { updateSaveTime(out, inData.doc) } catch (err) { };  // updates upon saving only when a 'doc' is in inData
      return [out, false];
    } else {
      if (text === "cannotOverwrite") _T.alert(`Someone else is working on the same data. 
        Your changes will be lost.  Please exit and try again later`,
        "Your Data Cannot be Saved");
      return [{}, text];
    }
  } catch (err) { console.log("ERR",err.message)}
  return [{}, ""];

  function updateSaveTime(result, inData) {
    if (!inData) return;
    if (result.ok && result?.stamp > 10000) inData.stamp = result.stamp;
    if (result.ok && result?._version > 0) inData._version = result._version;
  }
}
//********************************************************************************************************
// _T.toast = async function (title = "", message = "", parent = false, smallMessage = "") {
//   let html = `<div class="w3-pale-blue toast mx-auto mt-3" data-autohide="true" data-animation="true" data-delay=2000>
//     <div class="toast-header">
//       <strong class="mr-auto text-primary">${ title }</strong>
//       <small class="text-muted"></small>
//       <button type="button" class="ml-2 mb-1 close" data-dismiss="toast">&times;</button>
//     </div>
//     <div class="toast-body">${ message }</div>
//   </div>`;
//   if (parent) $(parent).parent().append(html); else $(body).children().first().append(html);
//   $(".toast").toast("show").on("hidden.bs.toast", function () { $(this).remove() });
// }
//********************************************************************************************************

function DialogBox(props) {
  const [show, setShow] = useState(props.show);

  const handleCancel = (event) => {
    event.stopPropagation();
    try {props.handleCancel()} catch(err){}
    setShow(false);
    props.callback(false);
  }
  //======================================================
  const handleOk = (event) => {
    event.stopPropagation();
    let okToClose = true;
    try {
      okToClose = props.handleOk();
      if (okToClose === false) return;
    } catch (err) { }  
    setShow(false);
    if (typeof okToClose == "object") props.callback(okToClose); else {
      try {
        let input = document.querySelector("#toolsPromptInputID");
        if (!input) throw ("No input");
        if (input !== null) props.callback(input.value); else props.callback(true);
      } catch (err) { }
      props.callback(true);
    }
  }
  //======================================================

  let Actions = "";
  let hasActions = !(props.textOk === false && props.textCancel === false);
  if (hasActions) Actions = <DialogActions sx={{
    borderTop: props.actionsAtTop? 0:1+"px solid gray", borderBottom: props.actionsAtTop? 1:0+"px solid gray", mx: "1rem" }}>
    {props.textCancel && <Button variant="outlined" color="error" onClick={handleCancel} style={{ minWidth: props.minWidth }}>{props.textCancel}</Button>}
    {props.extraButtons.map(item => {
      try {
        return <Button variant="contained" onClick={() => { props.callback(item.func()) }} className={item.class}
          style={{ minWidth: props.minWidth }} key={item.label}>{item.label}</Button>
      } catch (err) { }
    })}
    <Button variant="contained" onClick={handleOk} style={{ minWidth: props.minWidth }}>{props.textOk}</Button>
  </DialogActions>;

  return (<>
    <Dialog open={show} onClose={handleCancel} keyboard="false" scroll="paper" fullScreen={props.fullScreen} maxWidth={props.width} fullWidth={true}>
      <DialogTitle className={hasActions? "w3-2021-cerulean" : "w3-2021-desert-mist"} >
        {props.title}
      </DialogTitle>
      {props.actionsAtTop && Actions}
      <Box sx={{ mx: "1rem", minHeight: props.minHeight || "auto"}}>
        {(!hasActions && ! _T.isEmpty(props.message)) && <DialogContent>{props.message}</DialogContent>}
        {hasActions && typeof props.message == "object" ? props.message : <DialogContent dangerouslySetInnerHTML={{ __html: props.message }} ></DialogContent>}
        {props.components !== false && <DialogContent>{props.components}</DialogContent>}
      </Box>
      {!props.actionsAtTop && Actions}
    </Dialog>
  </>);
}
DialogBox.defaultProps = {
  minWidth: 80,
}
//********************************************************************************************************
export default _T;