import { useContext, useState, useEffect } from 'react';
import {
  Box, Stack, Button, ButtonGroup, MenuItem, TextField, Paper, Table, TableBody,TableCell,TableContainer,TableHead,TableRow,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { PostAdd,ContentCopy,Delete,Colorize } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../App';

const headerClass = "w3-flat-peter-river"
let columns = [];
let objA = {};
let shortMenus = true;
//*********************************************************************************************************************
function Admin(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  let navigate = useNavigate();
  let [subscribers, setSubscribers] = useState([]);

  //======================================================
  function handleChange(event) {
    event.preventDefault();
    try {
      // console.log(event.target);
      let obj = {};
      if (event.target.type === "checkbox") {
        obj[event.target.name] = !!! obj[event.target.name];
      } else obj[event.target.name] = event.target.value;
    } catch (err) { }
  }
  //======================================================
  async function isPublished(accountNo,name) {
  }
  //======================================================
  async function views() {
  }
  //======================================================
  async function getSubscribers() {
    let [subs, _] = await ctx.T.post("find", { collection: "Users", query: {}, options: { projection: { _id: 1, password: 0 } } });
    for (let usr of subs) {
      usr.schedules = await getSchedules(usr);
    }
    setSubscribers(subs);
  }
  //======================================================
  async function getSchedules(usr) {
    let [ar, _] = await ctx.T.post("find", { collection: "Schedule", query: { ownerID: usr._id }, options: { projection: { _id: 1, info: 1, stamp: 1 } } });
    if(ar.length <= 0) return [{info:{name:" --- "}}]
    ar.sort((a, b) => {
      let out = a.info.name === b.info.name;
      if (out) return b.stamp - a.stamp; else return b.info.name < a.info.name;
    });

    // Remove duplicates choosing the latest version based on sort above
    ar = ar.reduce((arOut,elem,nn) => {
      if (nn == 0
        || (nn > 0
          && (elem.info.name !== ar[nn - 1].info.name || elem.ownerID != ar[nn - 1].ownerID)
      )) arOut.push(elem);
      return arOut;
    },[]);
    return ar;
  }
  //======================================================
  useEffect(() => {
    getSubscribers();
  },[])
  //======================================================

  const buttons = [
    // <Button key="one"  onClick={newSchedule}>New Schedule</Button>,
    // <Button key="two" onClick={openSchedule}>Open</Button>,
    // <Button key="three" onClick={makeBackup}>Create Backup</Button>,
    // <Button key="seven" onClick={grantAccess}>Grant Access</Button>,
    // <Button key="eight" onClick={deleteSchedule}>Delete</Button>,
  ]

  return (<>
    <Box sx={{display:"flex", flexDirection:"row", px:2}}>
      <Box component="h2" sx={{ flexGrow:1}}>Subscribers</Box>
    </Box>
    <TableContainer component={Paper} sx={{ width:"90%", m:"auto" }} >
      <Table>
        <TableHead className="w3-light-gray"><TableRow>
          <TableCell>&nbsp;</TableCell>
          <TableCell>Subscriber</TableCell>
          <TableCell>Schedule Name</TableCell>
          <TableCell>Published</TableCell>
          <TableCell>Last Edited</TableCell>
          <TableCell>Last Viewed</TableCell>
          <TableCell>Views</TableCell>
        </TableRow></TableHead>
        <TableBody>
          {subscribers.map(usr => {
            return usr.schedules.map((sched,nn) => {
              return <TableRow key={nn}>
                {nn == 0 && <TableCell rowSpan={usr.schedules.length}>&nbsp;</TableCell>}
                {nn == 0 && <TableCell rowSpan={usr.schedules.length} sx={{ verticalAlign: "top" }}>
                  {usr.email}<br />Account no: {usr.accountNo} <br />Last login: {ctx.T.dateTime(usr.stamp)}
                </TableCell>}
                <TableCell>{sched.info.name}</TableCell>
                <TableCell>1</TableCell>
                <TableCell>{ctx.T.dateTime(sched.stamp ?? 0)}</TableCell>
                <TableCell>2</TableCell>
                <TableCell>3</TableCell>
              </TableRow > })
          })}
        </TableBody>
      </Table>

    </TableContainer>
  </>)
}


export default Admin;