import { Box, Stack, Grid, Button, ButtonGroup, Container, FormGroup, TextField, Menu, MenuItem } from '@mui/material';
import DatePicker, {Calendar, DateObject} from "react-multi-date-picker";
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../App';


//********************************************************************************************************
function BasicInfo(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  ctx.schedule.info ||= {};
  let [info, setInfo] = useState(ctx.schedule.info);
  let [validation, setValidation] = useState({});
  let navigate = useNavigate();

  if (ctx.T.user._id === ctx.schedule.ownerID) ctx.schedule.info.accountNo = ctx.T.user.accountNo;  // fix account numbers
  //======================================================
  function handleChange(event) {
    event.preventDefault();
    try {
      // console.log(event.target);
      let obj = {...info};
      if (event.target.type === "checkbox") {
        obj[event.target.name] = !!! obj[event.target.name];
      } else obj[event.target.name] = event.target.value;
      ctx.schedule.info = obj;
      if(event.target.name === "startDate") fillHolidays();
      if(event.target.name === "endDate") fixEndDate(obj);
      setInfo(ctx.schedule.info);
    } catch (err) { }
  }
  //======================================================
  function fixEndDate(obj) {
    if (obj.startDate.substring(0, 4) !== obj.endDate.substring(0, 4)) {
      setValidation({...validation, errEndDate: true });
      return;
    }
    let d1 = new Date(obj.startDate);
    let d2 = new Date(obj.endDate);
    if (d2.getTime() <= d1.getTime()) {
      setValidation({...validation, errEndDate: true });
      return;
    }
    setValidation({...validation, errEndDate: false });
  }
  //======================================================
  function fillHolidays(year) {
    year ||= new Date().getFullYear();
    try {
      year = ctx.schedule.info.startDate.substring(0, 4);
    } catch (err) { }
    year = Number(year);
    let novs = novDays();    
    ctx.schedule.info.newYear = `${ year }-01-01`;
    ctx.schedule.info.memorialDay = memorialDay();
    ctx.schedule.info.goodFriday = goodFriday();
    ctx.schedule.info.july4 = `${year}-07-04`;
    ctx.schedule.info.laborDay = laborDay();
    ctx.schedule.info.thanksgivingDay = novs[0];
    ctx.schedule.info.blackFriday = novs[1];
    ctx.schedule.info.christmas = `${ year }-12-25`;

    function memorialDay() {
      let lastDay = 31;
      let dt = new Date(year, 4, lastDay);
      while (dt.getDay() !== 1) {
        dt = new Date(year, 4, --lastDay)
      }
      return dt.toISOString().substring(0,10);
    }
    function laborDay() {
      let day1 = 1;
      let dt = new Date(year, 8, day1);
      while (dt.getDay() !== 1) {
        dt = new Date(year, 8, ++day1)
      }
      return dt.toISOString().substring(0,10);
    }
    function goodFriday() { // based on USNO method
      let C = Math.floor(year/100);
      let N = year - 19 * (Math.floor(year/19));
      let k = Math.floor((C - 17)/25);
      let I = C - Math.floor(C/4) - Math.floor((C - k)/3) + 19 * N + 15;
      I = I - 30 * Math.floor(I/30);
      I = I - Math.floor(I/28) * (1 - Math.floor(I/28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
      let J = year + Math.floor(year/4) + I + 2 - C + Math.floor(C/4);
      J = J - 7 * Math.floor(J/7);
      let L = I - J;
      let m = 3 + Math.floor((L + 40) / 44);
      let d = L + 28 - 31 * Math.floor(m/4) - 2;
      let dt = new Date(year, m-1, d);
      return dt.toISOString().substring(0,10);
    }
    function novDays() {
      let day1 = 1, nThu = 0;
      let dt = new Date(year, 10, day1);
      while (nThu !== 4) {
        while (dt.getDay() !== 4) {
          dt = new Date(year, 10, ++day1)
        }
        nThu++;
        if(nThu !== 4) dt = new Date(year, 10, ++day1)
      }
      let d1 = dt.toISOString().substring(0,10);
      dt = new Date(year, 10, ++day1);
      let d2 = dt.toISOString().substring(0, 10);
      return [d1, d2];
    }
  }
  //======================================================
  function textField(label, dataSlug, helperText="", errSlug="") {
    return (
      <TextField variant="outlined" size="small" name={dataSlug} value={info[dataSlug]}
        error={validation[errSlug] ? validation[errSlug] : false} helperText={helperText} 
        sx={{ width: "100%", mt:2 }} label={label} onChange={handleChange} focused/>
    )
  }
  //======================================================
  function dateField(label, dataSlug, helperText="", errSlug="") {
    // let formatOut = "YYYY-MM-DD";
    // let formatShow = "MM/DD/YYYY";
    return (
      // <DatePicker name={dataSlug} value={ctx.T.formatDate(info[dataSlug], formatOut, formatShow)} format = { formatShow } portal = { true} zIndex = { 10000} />
      <TextField variant="filled" type="date" size="small" name={dataSlug} value={info[dataSlug]}
        error={validation[errSlug] ? validation[errSlug] : false} helperText={helperText} 
        sx={{ width: "100%", mt: 2 }} label={label} onChange={handleChange} focused/>
    )
  }
  //======================================================
  async function saveInfo() {
    let [result,_] = await ctx.T.post("saveSchedule", {_id: ctx.schedule._id, doc: { info: ctx.schedule.info } });
    if (!result.ok) ctx.T.alert("Data could not be saved","Saving");
  }
  //======================================================
  useEffect(() => {
    if (ctx.T.isBlank(info.accountNo)) info.accountNo = ctx.T.user.accountNo;
  });
  //======================================================

  return (<>
    <Box sx={{display:"flex", flexDirection:"row", px:2}}>
      <Box component="h2" sx={{ flexGrow:1}}>Schedule Info</Box>
    </Box>
  
    <Grid container spacing={2} sx={{p:2}}>
      <Grid item sm={1}></Grid>
      <Grid item md={4} sm={6} xs={12} >
        {textField("Schedule Title", "name")}
        {textField("Schedule Name (brief)", "slug")}
        {textField("Security Code", "securityCode", "Leave blank to not require a security code")}
        <Stack direction="row" spacing={2} alignItems="flex-end">
          <TextField variant="outlined" size="small" name="Account No" value={info.accountNo} sx={{ width: "100%", mt:2 }} label="Account No" disabled/>
          {textField("Epic ID", "epicID")}
        </Stack>
        {dateField("Start Date", "startDate")}
        {dateField("End Date", "endDate", "End Date must be later than Start Date in the same year", "errEndDate")}
        <Stack justifyContent={"center"} sx={{m:1}}>
          <Button variant="outlined" onClick={()=>navigate("/activities")} sx={{width:200,m:"auto",mt:3}}>Duties to Schedule</Button>
          <Button variant="outlined" onClick={()=>navigate("/staff")} sx={{width:200,m:"auto",mt:3}}>Team Members</Button>
          <Button variant="outlined" onClick={()=>navigate("/month")} sx={{ width: 200, m: "auto", mt: 3 }}>Schedule View</Button>
        </Stack>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <h4>Standard Holidays</h4>
        {dateField("New Year's Day", "newYear")}
        {dateField("Good Friday", "goodFriday")}
        {dateField("Memorial Day", "memorialDay")}
        {dateField("Independence Day", "july4")}
        {dateField("Labor Day", "laborDay")}
        {dateField("Thanksgiving Day", "thanksgivingDay")}
        {dateField("Black Friday", "blackFriday")}
        {dateField("Christmas", "christmas")}
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <h4>Custom Holidays</h4>
        {dateField("Custom Holiday 1", "custom1")}
        {dateField("Custom Holiday 2", "custom2")}
        {dateField("Custom Holiday 3", "custom3")}
        {dateField("Custom Holiday 4", "custom4")}
        {dateField("Custom Holiday 5", "custom5")}
        {dateField("Custom Holiday 6", "custom6")}
        {dateField("Custom Holiday 7", "custom7")}
        {dateField("Custom Holiday 8", "custom8")}
      </Grid>
      <Grid item sm={1}></Grid>
      <Grid item sm={1}></Grid>
      <Grid item sm={10}>
        <TextField variant="outlined" size="small" name="notes" value={info.notes}
          sx={{ width: "100%", mt: 2 }} label="Notes" onChange={handleChange} multiline={true} minRows={3} focused/>        
      </Grid>
      <Grid item sm={1}></Grid>
    </Grid>
    <p /> 
  </>)
}


export default BasicInfo;